// @ts-nocheck
import React, { Fragment, useState } from "react";
import MaterialTable from "material-table";
import AppStyleWrapper from './AppStyleWrapper';
import DeloitteLogo from "./Deloitte_logo_ui_react.png"
import { useNavigate } from 'react-router-dom';
import Dropdown from "./form-inputs/Dropdown";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button } from "@mui/material";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import DialogActions from '@mui/material/DialogActions';
import exportFromJSON from 'export-from-json';
import { GlobalDataContext } from './context/GlobalDataContext'
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import HomeIcon from '@mui/icons-material/Home';

import {
    FormControl, InputLabel, MenuItem, Select, SelectProps, Link
} from "@mui/material"
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Autocomplete, Box, IconButton, SelectChangeEvent, Stack, Tab, Tabs, TextField, Table } from "@mui/material";


import Card, { CardProps } from "@mui/material/Card";
import { styled } from '@mui/material/styles';

const originalData = [
    {
        id: "client 1",
        name: "Jasnah",
        booleanValue: false
    },
    {
        id: "client 2",
        name: "Dal",
        booleanValue: true
    },
    {
        id: "client 3",
        name: "Kal",
        booleanValue: false
    }
];
const theme = createTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: "red",
                    fontWeight: "bold"
                }
            },

            focused: {}
        }
    }
});
const DarkCard = styled(Card)<CardProps>(({ theme }) => ({
    backgroundColor: '#333',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(248, 240, 227, 0.45)'
}));

function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: string) => {
        onClose(value);
    };

    return (
        <Dialog PaperProps={{
            style: {
                backgroundColor: 'white',
                boxShadow: 'none',
                color: 'black'
            },
        }} onClose={handleClose} open={open}>
            <DialogTitle>Client Onboarding details captured successfully</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface DropdownProps extends SelectProps<string> {
    title?: string,
    options: string[],
    size?: "small" | "medium"
    minWidth?: number,
    formControlStyles?: React.CSSProperties
}
//clientname: 'exampleclient',
//         projectname: 'exampleclient-risk-insurance',
//         process: 'Industry Model',
//         modeltype: 'UW',
//         lob: 'WC',
//         clientspoc: 'abc@exampleclient.com',
//         environment: 'dev;prod',
//         primaryregion: 'us-east-1',
//         backupRegion: 'us-ewest-2',
//         dashboardcount: '5',
//         useremail: 'abc@exampleclient.com; def@exampleclient.com',
//         cloudadmin: 'pmazumder@deloitte.com; anandnaray@deloitte.com; hsrinivasan@deloitte.com',
//         mlengineer: 'gachandler@deloitte.com',
//         dataScientists: 'jaysarkar@deloitte.com',
//         requestsubmissiondate: '4/28/2023'
//     }
type VarGraphOptions = {
    infra: string,
    modeltype: string,
    lob: string,
    primaryregion: string,
    secondaryregion: string,
    projectname: string,
    clientspoc: string,
    environment: string,
    dashboardcount: string,
    clientname: string,
    useremail: string,
    cloudadmin: string,
    mlengineer: string,
    datascientist: string,
    requestsubmissiondate: string,
    status: string
}

// type OptionsInput = {

//     setOptions: (newOptions: VarGraphOptions) => void,
//     selectedOptions: VarGraphOptions
// }

function OptionsSelector({ selectedOptions, setOptions }: OptionsInput) {

    const [globalData, setGlobalData] = React.useContext(GlobalDataContext) as any
    console.log(globalData)
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const [originalData, setOrg] = React.useState([
        {
            user: "pmazumder@deloitte.com",
            name: "Prithvi Mazumder",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "anandnaray@deloitte.com",
            name: "Anand Naryanaswamy",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "hsrinivasan@deloitte.com",
            name: "Hariharan Srinivasan",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "gachandler@deloitte.com",
            name: "Gavi Shandler",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "jaysarkar@deloitte.com",
            name: "Jay Kumar Sarkar",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "macarrier@deloitte.com",
            name: "Matthew Carrier",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "faraismail@deloitte.com",
            name: "Farah Ismail",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "schakali@deloitte.com",
            name: "Sravanthi Chakali",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "jlohani@deloitte.com",
            name: "Lohani Jayant",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "apoorvsharma4@deloitte.com",
            name: "Sharma Apoorv",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "lchhabra@deloitte.com",
            name: "Lovish Chhabra",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "almariappan@deloitte.com",
            name: "Mariappan, Alagammal",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "ritchoudhary@deloitte.com",
            name: "Ritika Choudhary",
            cloudadmin: false,
            tenantadmin: false,
            mlengineer: false,
            datascientist: false
        }
    ]);

    const [data, setData] = useState(originalData);
    const [submitButton, setSubmit] = useState(true);

    const [message, setMessage] = useState();
    const tableColumns = [
        { title: "Users", field: "name" },

        {
            title: "Cloud Admin",
            field: "cloudadmin",
            render: (rowdata) => (
                <input type="checkbox" onChange={(e) => changeAdmin(e.target.checked, rowdata)} />
            ),
            cellStyle: {
                //backgroundColor: '#039be5',
                color: '#FFF',
                textAlign: 'center' as const,

            },
            // rowStyle: () => {
            //     return {
            //       fontFamily: "Mulish-Regular",
            //       backgroundColor: "#fff",
            //       marginLeft: '2rem'
            //     };
            //   },
            headerStyle: {
                alignItems: 'right' as const,
                //backgroundColor: '#039be5',
            }
        },
        {
            title: "Tenant Admin",
            field: "tenantadmin",
            render: (rowdata) => (
                <input type="checkbox" onChange={(e) => tenantadmin(e.target.checked, rowdata)} />
            ),
            cellStyle: {
                //backgroundColor: '#039be5',
                color: '#FFF',
                textAlign: 'center' as const,

            },
            headerStyle: {
                alignItems: 'center' as const,
                //backgroundColor: '#039be5',
            }
        },
        {
            title: "ML Engineer",
            field: "mlengineer",
            render: (rowdata) => (
                <input type="checkbox" onChange={(e) => mlengineer(e.target.checked, rowdata)} />
            ),
            cellStyle: {
                //backgroundColor: '#039be5',
                color: '#FFF',
                textAlign: 'center' as const,

            },
            headerStyle: {
                alignItems: 'center' as const,
                //backgroundColor: '#039be5',
            }
        },
        {
            title: "Data Scientist",
            field: "datascientist",
            render: (rowdata) => (
                <input type="checkbox" onChange={(e) => datascientist(e.target.checked, rowdata)} />
            ),
            cellStyle: {
                //backgroundColor: '#039be5',
                color: '#FFF',
                textAlign: 'center' as const,

            },
            headerStyle: {
                alignItems: 'center' as const,
                //backgroundColor: '#039be5',
            }
        },

    ];
    const [adminList, setAdminList] = useState([])
    const [adminStrin, setAdmin] = useState('')
    const changeAdmin = (event: any, rowdata: any) => {
        //  return (event: SelectChangeEvent) => {
        //     let temp = { ...selectedOptions } as VarGraphOptions
        //     temp[field] = event.target.value
        //     setOptions(temp)
        //     setMessage(event.target.value)
        // }
        console.log(event, rowdata)
        var temp = originalData
        ///var list=[...adminList];
        //if(rowdata)
        var objIndex = originalData.findIndex((obj => obj.user == rowdata.user));
        console.log("Before update: ", originalData[objIndex])

        //Update object's name property.
        temp[objIndex].cloudadmin = event
        //console.log("Before update: ", adminList.push(rowdata.user), adminList)
        let cnames = ''
        if (adminList && event === true) {
            adminList.push(rowdata.user)
            const uniqueNames = Array.from(new Set(adminList));
            cnames = uniqueNames.map((item => item)).join(', ')
        } else {
            var index = adminList.findIndex(item => item === rowdata.user)
            adminList.splice(index)
            const uniqueNames = Array.from(new Set(adminList));
            cnames = uniqueNames.map((item => item)).join(', ')
        }
        let init = { ...selectedOptions } as VarGraphOptions
        init['cloudadmin'] = cnames
        setOptions(init)
        console.log(cnames)

        setMessage(cnames)
        setAdmin(cnames)
        setOrg(temp)
        //setAdminList(list.push(event))

    }
    const [tenantList, setTenantList] = useState([])
    const [tenantString, setTenant] = useState()
    const tenantadmin = (event: any, rowdata: any) => {
        console.log(event, rowdata)
        var temp = originalData
        //if(rowdata)
        var objIndex = originalData.findIndex((obj => obj.user == rowdata.user));
        console.log("Before update: ", originalData[objIndex])

        //Update object's name property.
        temp[objIndex].tenantadmin = event
        console.log("Before update: ", temp)
        let cnames = ''

        if (tenantList && event === true) {
            tenantList.push(rowdata.user)            
            const uniqueNames = Array.from(new Set(tenantList));
            cnames = uniqueNames.map((item => item)).join(', ')
        } else {
            var index = tenantList.findIndex(item => item === rowdata.user)
            tenantList.splice(index)            
            const uniqueNames = Array.from(new Set(tenantList));
            cnames = uniqueNames.map((item => item)).join(', ')
        }

        let init = { ...selectedOptions } as VarGraphOptions
        init['tenantadmin'] = cnames
        setOptions(init)
        console.log(cnames)
        setMessage(cnames)
        setTenant(cnames)
        setOrg(temp)

    }
    const [mlList, setMLList] = useState([])
    const [mlString, setML] = useState()
    const mlengineer = (event: any, rowdata: any) => {
        console.log(event, rowdata)
        var temp = originalData
        //if(rowdata)
        var objIndex = originalData.findIndex((obj => obj.user == rowdata.user));
        console.log("Before update: ", originalData[objIndex])

        //Update object's name property.
        temp[objIndex].mlengineer = event
        console.log("Before update: ", temp)
        let cnames = ''

        if (mlList && event === true) {
            mlList.push(rowdata.user)          
            const uniqueNames = Array.from(new Set(mlList));
            cnames = uniqueNames.map((item => item)).join(', ')
        } else {
            var index = mlList.findIndex(item => item === rowdata.user)
            mlList.splice(index)          
            const uniqueNames = Array.from(new Set(mlList));
            cnames = uniqueNames.map((item => item)).join(', ')
        }

        let init = { ...selectedOptions } as VarGraphOptions
        init['mlengineer'] = cnames
        setOptions(init)
        console.log(cnames)
        setML(cnames)
        setOrg(temp)
        setMessage(cnames)

    }
    const [dataScientstList, setDSList] = useState([])
    const [dsString, setDS] = useState()
    const datascientist = (event: any, rowdata: any) => {
        console.log(event, rowdata)
        var temp = originalData
        //if(rowdata)
        var objIndex = originalData.findIndex((obj => obj.user == rowdata.user));
        console.log("Before update: ", originalData[objIndex])

        //Update object's name property.
        temp[objIndex].datascientist = event
        console.log("Before update: ", temp)
        let cnames = ''
        if (dataScientstList && event === true) {
            dataScientstList.push(rowdata.user)       
            const uniqueNames = Array.from(new Set(dataScientstList));
            cnames = uniqueNames.map((item => item)).join(', ')
        } else {
            var index = dataScientstList.findIndex(item => item === rowdata.user)
            dataScientstList.splice(index)       
            const uniqueNames = Array.from(new Set(dataScientstList));
            cnames = uniqueNames.map((item => item)).join(', ')
        }

        let init = { ...selectedOptions } as VarGraphOptions
        init['datascientist'] = cnames
        setOptions(init)
        console.log(cnames)
        setDS(cnames)
        setOrg(temp)
        setMessage(cnames)

    }
    React.useEffect(() => {
        // Set empty erroMessage only if text is less than MAX_LENGTH
        // and errorMessage is not empty.
        // avoids setting empty errorMessage if the errorMessage is already empty

        if (selectedOptions && (selectedOptions.clientname === '' || selectedOptions.projectname === '' || selectedOptions.clientspoc === '' || selectedOptions.environment === ''
            || selectedOptions.dashboardcount === ''
            || selectedOptions.clientname === '' || selectedOptions.cloudadmin === '' || selectedOptions.mlengineer === '' || selectedOptions.datascientist === '' || selectedOptions.tenantadmin === '')) {
            setSubmit(true)
        } else {
            setSubmit(false)
        }
    }, [selectedOptions, message]);

    const submit = () => {
        // var hardCOded = {
        //     infra: 'industry',
        //     modeltype: 'UW',
        //     clientname: 'exampleclient',
        //     lob: 'WC',
        //     primaryregion: 'us-west-2',
        //     secondaryregion: 'us-west-1',
        //     ipAddress: '10.0.4.0/22',
        //     vpcSubnetRange: '',
        //     vpcSubnetOne: '10.0.4.0/24',
        //     vpcSubnetTwo: '10.0.5.0/24',
        //     vpcSubnetThree: '10.0.6.0/24'
        // }
        // var download = [
        //     {
        //         clientname: 'exampleclient',
        //         projectname: 'exampleclient-risk-insurance',
        //         process: 'Industry Model',
        //         modeltype: 'UW',
        //         lob: 'WC',
        //         clientspoc: 'abc@exampleclient.com',
        //         environment: 'dev;prod',
        //         primaryregion: 'us-east-1',
        //         backupRegion: 'us-ewest-2',
        //         dashboardcount: '5',
        //         useremail: 'abc@exampleclient.com; def@exampleclient.com',
        //         cloudadmin: 'pmazumder@deloitte.com; anandnaray@deloitte.com; hsrinivasan@deloitte.com',
        //         mlengineer: 'gachandler@deloitte.com',
        //         dataScientists: 'jaysarkar@deloitte.com',
        //         requestsubmissiondate: '4/28/2023'
        //     }
        // ];
        const fileName = 'Client Onboarding'
        const exportType = exportFromJSON.types.csv;
        // exportFromJSON({ data: [selectedOptions], fileName: fileName, exportType: exportType });
        console.log(selectedOptions)
        //  fetch('https://kwz52tw5m9.execute-api.us-west-2.amazonaws.com/dev', {
        //     method: 'POST',
        //      mode: 'cors',
        //      body: JSON.stringify(selectedOptions),
        //      // headers: {
        //      //     'X-API-KEY': '',
        //      //     'Accept': 'application/json',
        //      //     'Content-Type': 'application/json'
        //      //   }
        //  })
        fetch('https://8a4zicmr7f.execute-api.us-east-1.amazonaws.com/default/mlops-framework-archi-creation', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(selectedOptions),
            // headers: {
            //     'X-API-KEY': '',
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            //   }
        })
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "text/plain"
            },
            body: JSON.stringify({
                "ClientName": selectedOptions.clientname,
                "Region": selectedOptions.primaryregion
            })
        }

        fetch("https://hnji7ehubg.execute-api.us-east-1.amazonaws.com/Staging/tenant-details-extraction", options)
            .then(response => response.json()) // or response.text()
            .then(data => {
                setGlobalData((e: any) => {
                    return {
                        ...e,
                        tenantDetail: data
                    }
                })
                console.log(globalData)
            })
            .catch(err => { console.log('error while fetching', err) });

        //setOpen(true);
        //setGlobalData(hardCOded)
        setGlobalData((e: any) => {
            return {
                ...e,
                tenant: selectedOptions
            }
        })
        console.log(globalData)
        navigate('/tenantoverview')
    }

    const [valid, setValid] = useState(true)
    const onChange = (field: 'infra' | 'lob' | 'modeltype' | 'secondaryregion' | 'primaryregion') => {
        return (event: SelectChangeEvent) => {
            let temp = { ...selectedOptions } as VarGraphOptions
            temp[field] = event.target.value
            setOptions(temp)
            const reg = new RegExp("[a-z]");
            if (event.target.value === '') {
                setValid(true);
            } else {
                setValid(reg.test(event.target.value));
            }
            setMessage(event.target.value)
        }
    }
    const handleChange = (field: 'projectname' | 'clientspoc' | 'dashboardcount' | 'useremail' | 'clientname' | 'environment') => {
        return (event: any) => {
            let temp = { ...selectedOptions } as VarGraphOptions
            temp[field] = event.target.value
            temp['status'] = 'Pending'
            setOptions(temp)
            const reg = new RegExp("[a-z]");
            if (event.target.value === '') {
                setValid(true);
            } else {
                setValid(reg.test(event.target.value));
            }
            setMessage(event.target.value)
        }
    }
    console.log(selectedOptions)
    interface PageStateNoConflict {
        options: VarGraphOptions,
        modalOpen: false
    }

    interface PageStateInConflict {
        options: VarGraphOptions,
        proposedOptions: VarGraphOptions,
        modalOpen: true
    }

    type PageState = PageStateNoConflict | PageStateInConflict

    const handleClose = (value: string) => {
        setOpen(false);
    };
    const home = () => {
        navigate('/')
    };
    const [pageState, setPageState] = React.useState<PageState>({
        modalOpen: false,
        options: {
            infra: 'Industry',
            modeltype: 'UW',
            lob: 'WC',
            primaryregion: 'us-east-1',
            secondaryregion: 'us-east-2',
            ipAddress: '',
            vpcSubnetRange: '',
            vpcSubnetOne: '',
            vpcSubnetTwo: '',
            vpcSubnetThree: '',
            clientname: ''

        }
    })



    return (
        <div className="flex flex-col h-screen">
            <div className="grow m-2" style={{ overflow: "hidden" }}>
                <div className="flex justify-between h-16 m-4 mt-0">
                    <div className="flex-shrink-0">
                        <img
                            className="image"
                            src={DeloitteLogo}
                            alt="Deloitte Logo"
                            style={{ height: "32px" }}
                        ></img>
                    </div>
                    <div className=" mx-4">
                        <h1 className="font-bold text-3xl">{'Client Onboarding Portal'}</h1>
                    </div>
                    <div className="self-start">
                        
                    </div>
                </div>

                <div className="flex flex-row flex-wrap gap-y-8 justify-center gap-x-8 w-full ">

                    <div className="grow">
                        {/* <div style={{ color: 'white',marginLeft:'52rem' }} className="flex flex-row  ml-5">
                        <div className="text-lg">Capture Client Onboarding Information</div>
                    </div> */}
                        {/* <Box sx={{ flexGrow: 0.2, width: 180, marginLeft: 4, marginTop: 4 }}>
                        <Dropdown
                            title="Cluster"
                            label="Cluster"
                            labelId={`select-Cluster-label`}
                            value={selectedOptions.cluster}
                            onChange={onChange('cluster')}
                            options={globalData ? globalData.cluster : ['new']}
                        />

                    </Box> */}
                      <div className="self-start">
                        <Link underline={"hover"} color="rgb(134 188 37)" href="/">
                            {/* {'<' + ' Home'} */}
                            <HomeIcon sx={{ width: '2rem',  height: '2rem', marginTop: "-3rem",marginLeft:'1rem',marginBottom:"0rem"}}></HomeIcon>
                        </Link>
                    </div>
                        <div className="flex flex-row  w-full -mt-4">

                            <div className=" flex flex-col gap-y-4  p-4 border-e-white-950">

                                <DarkCard className="flex flex-row gap-x-1 p-1">

                                    {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                                    <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                                        <div className="text-lg">Project Information</div>
                                    </div>
                                    {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                                </DarkCard>
                                <div className=" flex flex-col gap-y-5 p-1  border-e-white-950" >
                                    <div className=" flex flex-row p-4  border-e-white-950">
                                        <Box sx={{ flexGrow: 0.2, width: 200, color: 'red' }}>
                                            {/* <ThemeProvider theme={theme}>
                                                <TextField id="outlined-basic"  onChange={handleChange('clientname')} label="Client Name" variant="outlined" required />
                                           
                                        </ThemeProvider> */}

                                            <TextField sx={{ flexGrow: 0.2, width: 200, color: 'red' }} id="outlined-basic"
                                                //error={!valid}
                                                onChange={handleChange('clientname')} label="Client Name" variant="outlined" required />

                                        </Box>
                                        <Box sx={{ flexGrow: 0.2, width: 200, marginLeft: 4 }}>
                                            <TextField id="outlined-basic"
                                                //error={!valid} 
                                                onChange={handleChange('projectname')} label="Project Name" variant="outlined" required />

                                        </Box>
                                        <Box sx={{ flexGrow: 0.2, width: 180, marginLeft: 4 }}>
                                            <Dropdown
                                                title="Process"
                                                label="Infrastructure"
                                                labelId={`select-Infrastructure-label`}
                                                value={selectedOptions.infra}
                                                onChange={onChange('infra')}
                                                options={['Industry', 'Custom']}

                                            />

                                        </Box>
                                    </div>

                                    <div className=" flex flex-row p-4  border-e-white-950">
                                        <Box sx={{ flexGrow: 0.1, width: 200 }}>
                                            <Dropdown
                                                title="Model Type"
                                                value={selectedOptions.modeltype}
                                                onChange={onChange('modeltype')}
                                                label="modeltype"

                                                labelId={`select-modeltype-label`}
                                                //onChange={setDataset}
                                                options={['Claims', 'UW']}
                                            />

                                        </Box>
                                        <Box sx={{ flexGrow: 0.1, width: 200, marginLeft: 4 }}>
                                            <Dropdown
                                                title="Line of Business"
                                                value={selectedOptions.lob}
                                                onChange={onChange('lob')}
                                                label="lineOfBusiness"

                                                labelId={`select-lineOfBusiness-label`}
                                                //onChange={setDataset}
                                                options={['WC']}
                                            />

                                        </Box>
                                        <Box sx={{ flexGrow: 0.2, width: 200, marginLeft: 4 }}>
                                            <TextField id="outlined-basic" onChange={handleChange('clientspoc')} label="Client Contact" variant="outlined" required />

                                        </Box>
                                    </div>
                                </div>
                                {/* <PopupState variant="popover" popupId="demo-popup-popover">
                                {(popupState) => (
                                    <div>
                                        <Button variant="contained" {...bindTrigger(popupState)}>
                                            Open Popover
                                        </Button>
                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
                                        </Popover>
                                    </div>
                                )}
                            </PopupState> */}
                                {/* <div className="space-y-px  flex flex-col gap-y-4  p-4 border-r-2 border-e-white-950"></div> */}
                                {/* <div className="w-full space-y-px  flex flex-col gap-y-4  p-4  ml-24 border-e-white-950"> */}
                                <DarkCard className="flex flex-row gap-x-4 p-1">

                                    {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                                    <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                                        <div className="text-lg">Platform Details</div>
                                    </div>
                                    {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                                </DarkCard>
                                <div className="flex flex-col gap-y-4  p-1  border-e-white-950">
                                    <div className=" flex flex-row  p-4  border-e-white-950">
                                        <Box sx={{ flexGrow: 0.2, width: 200 }}>
                                            <TextField id="outlined-basic" onChange={handleChange('environment')} label="Environment" variant="outlined" required />

                                        </Box>
                                        {/* <Box sx={{ flexGrow: 2 }}>


                </Box> */}


                                        <Box sx={{ flexGrow: 0.2, width: 190, marginLeft: 4 }}>
                                            <Dropdown
                                                title="Primary region"
                                                value={selectedOptions.primaryregion}
                                                onChange={onChange('primaryregion')}
                                                label="primaryregion"
                                                labelId={`select-primaryregion-label`}
                                                //onChange={setDataset}
                                                options={['us-east-2', 'us-east-1', 'us-west-1', 'us-west-2']}
                                                //minWidth={120}
                                                //sx={{ flexGrow: 1 }}
                                                autoWidth
                                            />
                                        </Box>
                                        <Box sx={{ flexGrow: 0.2, width: 190, marginLeft: 4 }}>
                                            <Dropdown
                                                title="Backup region"
                                                value={selectedOptions.secondaryregion}
                                                onChange={onChange('secondaryregion')}
                                                label="secondaryregion"

                                                labelId={`select-secondaryregion-label`}
                                                //onChange={setDataset}
                                                options={['us-east-2', 'us-east-1', 'us-west-1', 'us-west-2']}
                                            // minWidth={120}
                                            // sx={{ flexGrow: 1 }}
                                            />
                                        </Box>
                                    </div>
                                    <div className=" flex flex-row p-4  border-e-white-950">
                                        <Box sx={{ flexGrow: 0.2, width: 130 }}>
                                            <TextField id="outlined-basic" onChange={handleChange('dashboardcount')} label="Dashboard User Count" variant="outlined" required />

                                        </Box>
                                        <Box sx={{ flexGrow: 0.2, width: 120, marginLeft: 4 }}>
                                            <TextField id="outlined-basic" onChange={handleChange('useremail')} label="Email Address" variant="outlined"
                                                autoComplete='on' />
                                        </Box>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-1 border-r-2 border-e-white-950"></div>
                            <div className=" flex flex-col gap-y-4  p-4 border-e-white-950">
                                {/* <DarkCard className="flex flex-row gap-x-4 p-1"> */}
                                {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                                {/* <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                                    <div className="text-lg">User Assignment</div>
                                </div> */}
                                {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                                {/* </DarkCard> */}
                                <Fragment>
                                    <MaterialTable
                                        columns={tableColumns}
                                        title='User Assignment'
                                        data={data}
                                        options={{
                                            search: true, showTitle: true,
                                            toolbar: true, paging: true,

                                            pageSize: 7,       // make initial page size
                                            emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                            pageSizeOptions: [7,14],
                                            actionsColumnIndex: -1,
                                            headerStyle: {
                                                whiteSpace: "nowrap",
                                                height: 50,
                                                maxHeight: 50,
                                                padding: 2,
                                                textAlign: 'center',
                                                fontSize: "1rem"
                                            },
                                            rowStyle: {
                                                whiteSpace: "nowrap",
                                                height: 50,
                                                maxHeight: 50,
                                                padding: 0,
                                                textAlign: 'center'
                                            }
                                        }}
                                        localization={{
                                            pagination: {
                                                labelDisplayedRows: '{from}-{to} of {count}'
                                            },
                                            toolbar: {
                                                nRowsSelected: '{0} row(s) selected'
                                            },
                                            header: {
                                                actions: 'Actions'
                                            },
                                            body: {
                                                emptyDataSourceMessage: 'No records to display',
                                                filterRow: {
                                                    filterTooltip: 'Filter'
                                                }
                                            }
                                        }}
                                    // editable={{
                                    //     onRowUpdate: (newData, oldData) =>
                                    //       new Promise((resolve, reject) => {
                                    //         setTimeout(() => {
                                    //           console.log("old data: ", oldData);
                                    //           const dataUpdate = [...data];
                                    //           const index = 1;
                                    //           dataUpdate[index] = newData;
                                    //           setData([...dataUpdate]);
                                    //         }, 1000);
                                    //       })
                                    //   }}
                                    />
                                </Fragment>
                            </div>
                        </div>
                        <div className="self-center w-full flex justify-between h-8 m-4 mt-5">
                            <Box sx={{ flexGrow: 5, marginLeft: '42rem' }}>
                                <Button variant="outlined" onClick={submit} disabled={submitButton}>
                                    Submit
                                </Button></Box>
                        </div>
                        <SimpleDialog
                            selectedValue='Dialog'
                            open={open}
                            onClose={handleClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OptionsSelector;