import React, { useState } from 'react';
import AppStyleWrapper from './AppStyleWrapper';
import OptionsSelector from './OptionsSelector';
import Layout from './Layout';

import TenantManagement from './TenantManagement';

import TenantDetail from './TenantDetail';
import DeloitteLogo from "./Deloitte_logo_ui_react.png"
import Dropdown from "./form-inputs/Dropdown";
import { Button } from "@mui/material";
import { PageDefinition } from './SidebarData';
import { GlobalDataContext } from './context/GlobalDataContext'

import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"

import { Autocomplete, Box, IconButton, SelectChangeEvent, Stack, Tab, Tabs, TextField } from "@mui/material";

import Card, { CardProps } from "@mui/material/Card";
import { styled } from '@mui/material/styles';
type ClusterDetails = {
    clientname: string,
    projectname: string
}
 type Input = {
     setOptions: (newOptions: ClusterDetails) => void,
     selectedOptions: ClusterDetails
 }
type LayoutProps = {
    page: import('./SidebarData').PageDefinition
}
const DarkCard = styled(Card)<CardProps>(({ theme }) => ({
    backgroundColor: '#333',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(248, 240, 227, 0.45)'
}));

interface DropdownProps extends SelectProps<string> {
    title?: string,
    options: string[],
    size?: "small" | "medium"
    minWidth?: number,
    formControlStyles?: React.CSSProperties
}


type VarGraphOptions = {
    infra: string,
    modelType: string,
    lob: string,
    primaryregion: string,
    secondaryregion: string,
    ipAddress: string,
    clientname: string,
    vpcSubnetRange: string,
    vpcSubnetOne: string,
    vpcSubnetTwo: string,
    vpcSubnetThree: string,
}
type OptionsInput = {
    setOptions: (newOptions: VarGraphOptions) => void,
    selectedOptions: VarGraphOptions
}

type VarOptionsConflictResolution = {
    options: VarGraphOptions,
    proposedOptions: VarGraphOptions
}

const getConflicts = (options: VarGraphOptions, proposedOptions: VarGraphOptions) => {
    return {
        options,
        proposedOptions: {
            ...proposedOptions
        }
    } as VarOptionsConflictResolution
}


const ClusterCreation = () => {

    const [globalData, setGlobalData] = React.useContext(GlobalDataContext) as any
    interface PageStateNoConflict {
        options: VarGraphOptions
    }

    interface PageStateInConflict {
        options: VarGraphOptions
    }

    type PageState = PageStateNoConflict | PageStateInConflict


    const setOptions = (proposedOptions: VarGraphOptions) => {
        const conflictResult = getConflicts(pageState.options, proposedOptions)
        console.log(conflictResult)
        setPageState({
            options: conflictResult.proposedOptions
        })
    }

    const [pageState, setPageState] = React.useState<PageState>({
        options: {
            infra: 'Industry',
            modelType: 'UW',
            lob: 'WC',
            primaryregion: 'us-east-1',
            secondaryregion: 'us-east-2',
            ipAddress: '',
            vpcSubnetRange: '',
            vpcSubnetOne: '',
            vpcSubnetTwo: '',
            vpcSubnetThree: '',
            clientname: ''
        }
    })
    const [cluster, setCluster] = useState(false);
    const newCluster = () => {
        setCluster(true)
    }
    const[opt,setOpt]=useState()
    const [initial, setInitial] = useState({
            clientname: 'new',
            projectname:'new'
        }
    )
    const [users, setUsers] = useState([])

//const [user, setUser] = useState<ClusterDetails | null>(null);
    const handleChange = (field: 'clientname' | 'projectname') => {
    
        return (event: any) => {
            //userstemp[field] = event.target.value
            setUsers(event.target.value)
            console.log(event.target.value)
        }
    }
    const submit = () => {

        setGlobalData((e: any) => {
            return {
                ...e,
                cluster: [users]
            }
        })
    }
    return (
        <div className="flex flex-row flex-wrap gap-y-8 justify-center gap-x-8 w-full">

            <div className="w-full  flex flex-col ">
                <div className="flex flex-col w-full  gap-y-4 items-center">
                    <DarkCard className="w-full  flex flex-row gap-x-4 gap-y-4 p-1">

                        {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                        <div style={{ color: 'white' }} className="w-full  flex flex-row">
                            <div className="text-lg">New Cluster</div>
                        </div>
                        {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                    </DarkCard>
                    <div className=" w-full  flex flex-col  gap-x-4 gap-y-4  items-center">
                        <DarkCard className="flex flex-col gap-x-4 p-1  mt-5">

                            {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                            <div style={{ color: 'white', width: '48rem' }} className="flex flex-row ">
                                <div className="text-lg">New Project Details</div>
                            </div>
                            {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                        </DarkCard>
                        <Box sx={{ flexGrow: 0.2, width: 200 }}>
                            <TextField id="outlined-basic" label="Client Name" onChange={handleChange('clientname')} variant="outlined" />

                        </Box>
                        <Box sx={{ flexGrow: 0.2, width: 200 }}>
                            <TextField id="outlined-basic" label="Project Name" onChange={handleChange('projectname')} variant="outlined" />

                        </Box>

                        <button onClick={submit} style={{ backgroundColor: "white", color: 'black', width: '5rem', marginLeft: '4rem' }}>
                            Submit</button>

                        <DarkCard className="flex flex-col gap-x-4 p-1  mt-5">

                            {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                            <div style={{ color: 'white', width: '48rem' }} className="flex flex-row ">
                                <div className="text-lg ml-46">Currently this is a manual process</div>
                            </div>
                            {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                        </DarkCard>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ClusterCreation;