import React, { useState } from 'react';
import AppStyleWrapper from './AppStyleWrapper';
import OptionsSelector from './OptionsSelector';
import Layout from './Layout';

import ClusterCreation from './ClusterCreation';

import TenantDetail from './TenantDetail';
import DeloitteLogo from "./Deloitte_logo_ui_react.png"
import Dropdown from "./form-inputs/Dropdown";
import { Button } from "@mui/material";
import { PageDefinition } from './SidebarData';
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"
import { Autocomplete, Box, IconButton, SelectChangeEvent, Stack, Tab, Tabs, TextField, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Card, { CardProps } from "@mui/material/Card";
import { styled } from '@mui/material/styles';
type LayoutProps = {
    page: import('./SidebarData').PageDefinition
}
const DarkCard = styled(Card)<CardProps>(({ theme }) => ({
    backgroundColor: '#333',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(248, 240, 227, 0.45)'
}));

interface DropdownProps extends SelectProps<string> {
    title?: string,
    options: string[],
    size?: "small" | "medium"
    minWidth?: number,
    formControlStyles?: React.CSSProperties
}


type VarGraphOptions = {
    infra: string,
    modeltype: string,
    lob: string,
    primaryregion: string,
    secondaryregion: string,
    projectname: string,
    clientspoc: string,
    environment: string,
    dashboardcount: string,
    clientname: string,
    useremail: string,
    cloudadmin: string,
    mlengineer: string,
    datascientist: string,
    requestsubmissiondate: string,
    status: string
}
type OptionsInput = {
    setOptions: (newOptions: VarGraphOptions) => void,
    selectedOptions: VarGraphOptions
}

type VarOptionsConflictResolution = {
    options: VarGraphOptions,
    proposedOptions: VarGraphOptions
}

const getConflicts = (options: VarGraphOptions, proposedOptions: VarGraphOptions) => {
    return {
        options,
        proposedOptions: {
            ...proposedOptions
        }
    } as VarOptionsConflictResolution
}


function MainPage() {

    const navigate = useNavigate();

    interface PageStateNoConflict {
        options: VarGraphOptions
    }

    interface PageStateInConflict {
        options: VarGraphOptions
    }

    type PageState = PageStateNoConflict | PageStateInConflict


    const setOptions = (proposedOptions: VarGraphOptions) => {
        const conflictResult = getConflicts(pageState.options, proposedOptions)
        console.log(conflictResult)
        setPageState({
            options: conflictResult.proposedOptions
        })
    }

    const [pageState, setPageState] = React.useState<PageState>({
        options: {
            infra: 'Industry',
            modeltype: 'UW',
            lob: 'WC',
            primaryregion: 'us-east-1',
            secondaryregion: 'us-east-2',
            projectname: '',
            clientspoc: '',
            environment: '',
            dashboardcount: '',
            clientname: '',
            useremail: '',
            cloudadmin: '',
            mlengineer: '',
            datascientist: '',
            requestsubmissiondate: '',
            status: ''
        }
    })
    const [cluster, setCluster] = useState(false);
    const newCluster = () => {
        //setCluster(true)

        navigate('/cluster')
    }
    const newTenant = () => {
        //setCluster(true)

        navigate('/tenant')
    }
    const newUser = () => {
        //setCluster(true)

        navigate('/user')
    }
    const newOverview = () => {
        //setCluster(true)

        navigate('/tenantoverview')
    }
    return (
        <div className="flex flex-col h-screen">
            <div className="grow m-0">
                <div className="flex flex-col grow ">
                    <div className="flex justify-between h-16 m-4">
                        <div className="flex-shrink-0">
                            <img
                                className="image"
                                src={DeloitteLogo}
                                alt="Deloitte Logo"
                                style={{ height: "32px" }}
                            ></img>
                        </div>
                        <div className=" mx-4">
                            <h1 className="font-bold text-3xl mr-20">{'Client Onboarding Portal'}</h1>
                        </div>
                        <div className="self-start">

                        </div>
                    </div>
                    <Grid container columns={{ xs: 4, md: 12 }} columnSpacing={5} sx={{ marginTop: 20 }}
                        justifyContent="center"
                        alignItems="center">

                        <div className="flex flex-row flex-wrap gap-y-8 justify-center gap-x-8 w-full ">
                            {/* <Grid container direction="row" rowSpacing={3} columnSpacing={{ xs: 8, sm: 2, md: 3 }}
                            justifyContent="center"
                            alignItems="center"> */}
                            <Box>
                                <Button sx={{ flexGrow: 1, width: 350, height: 250, color: 'white' ,fontSize:'1.2rem',borderWidth:"0.4rem"}} variant="outlined" onClick={newCluster} disabled>
                                    New Cluster
                                </Button></Box>
                            <Box >
                                <Button sx={{ flexGrow: 1, width: 350, height: 250, marginLeft: 10, color: 'white',fontSize:'1.2rem' ,borderWidth:"0.4rem"}} onClick={newTenant} variant="outlined" >
                                    New Tenant
                                </Button></Box>
                            <Box>
                                <Button sx={{ flexGrow: 1, width: 350, height: 250, marginLeft: 10, color: 'white' ,fontSize:'1.2rem',borderWidth:"0.4rem"}} onClick={newUser} variant="outlined" disabled>
                                    User Management
                                </Button></Box>
                            {/* <Grid item xs={7} spacing={1} rowSpacing={3} columnSpacing={{ xs: 8, sm: 2, md: 5 }}
                                justifyContent="center"
                                alignItems="center"> */}

                                <Box >
                                    <Button sx={{ flexGrow: 1, width: 1280, height: 100, color: 'white', marginTop: '1rem',fontSize:'1.2rem' ,borderWidth:"0.4rem"}} onClick={newOverview} variant="outlined" >
                                        Manage existing Cluster/Tenant
                                    </Button></Box>
                            {/* </Grid> */}

                        </div>
                    </Grid ></div></div> </div>
    );
}

export default MainPage;