// @ts-nocheck
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"
import React from 'react';

interface DropdownProps extends SelectProps<string> {
    title?: string,
    options: string[],
    size?: "small" | "medium"
    minWidth?: number,
    formControlStyles?: React.CSSProperties
}


const Dropdown = ({title, value, label, options, onChange, ...props}: DropdownProps) => {
    
    return (
        <FormControl fullWidth size={props.size} sx={{ minWidth: props.minWidth, ...props.formControlStyles }}>
            {label && <InputLabel id={`select-${label}-label`}>{title}</InputLabel>}
            <Select
                labelId={`select-${label}-label`} 
                id={`select-${label ?? title}`}
                value={value}
                label={label}
                onChange={onChange}
            >
                {options.map((key, index) => <MenuItem value={key} key={index} disabled={(label==='Infrastructure'||label==='modeltype'||label==='lineOfBusiness'
                ||label==='primaryregion')?
                true:false}>{key}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default Dropdown