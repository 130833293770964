// @ts-nocheck
import React, { Fragment, useState } from "react";
import MaterialTable from "material-table";


import AppStyleWrapper from './AppStyleWrapper';
import DeloitteLogo from "./Deloitte_logo_ui_react.png"
import Dropdown from "./form-inputs/Dropdown";
import { Button } from "@mui/material";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CloseIcon from '@mui/icons-material/Close';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Accordion from '@mui/material/Accordion';

import HomeIcon from '@mui/icons-material/Home';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { useNavigate } from 'react-router-dom';
import { GlobalDataContext } from './context/GlobalDataContext'
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"
import RectangleIcon from '@mui/icons-material/Rectangle';
import { Autocomplete, Box, IconButton, SelectChangeEvent, Stack, Tab, Tabs, TextField, Table, Link } from "@mui/material";


import Card, { CardProps } from "@mui/material/Card";
import { styled } from '@mui/material/styles';

const originalData = [
    {
        id: "client 1",
        name: "Jasnah",
        booleanValue: false
    },
    {
        id: "client 2",
        name: "Dal",
        booleanValue: true
    },
    {
        id: "client 3",
        name: "Kal",
        booleanValue: false
    }
];
const DarkCard = styled(Card)<CardProps>(({ theme }) => ({
    backgroundColor: '#333',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(248, 240, 227, 0.45)'
}));

interface DropdownProps extends SelectProps<string> {
    title?: string,
    options: string[],
    size?: "small" | "medium"
    minWidth?: number,
    formControlStyles?: React.CSSProperties
}

type VarGraphOptions = {
    infra: string,
    modeltype: string,
    lob: string,
    primaryregion: string,
    secondaryregion: string,
    projectname: string,
    clientspoc: string,
    environment: string,
    dashboardcount: string,
    clientname: string,
    useremail: string,
    cloudadmin: string,
    mlengineer: string,
    datascientist: string,
    requestsubmissiondate: string,
    status: string
}

type OptionsInput = {

    setOptions: (newOptions: VarGraphOptions) => void,
    selectedOptions: VarGraphOptions
}

function TenantManagement() {
    const navigate = useNavigate();

    const originalData = [
        {
            user: "demo-client-tenant-dev",
            name: "demo-client-tenant",
            model: 'Industry',
            lob: '',
            status: 'Active',
            poc: '',
            env: '',
            admin: '',
            date: '',
            tenantDetails: '',
            tenantLogin: ''

        }
    ];

    const [globalData, setGlobalData] = React.useContext(GlobalDataContext)
    console.log(globalData)
    const [data, setData] = useState([]);
    const [tenantCount, setTenantCount] = useState([]);
    const [approvedData, setAppData] = useState([]);
    const [avgDeployment, setAverage] = useState();
    React.useEffect(() => {
        // Set empty erroMessage only if text is less than MAX_LENGTH
        // and errorMessage is not empty.
        // avoids setting empty errorMessage if the errorMessage is already empty
        //fetch("https://3t5f72pzkh.execute-api.us-west-2.amazonaws.com/Staging/tenantmanagement")
        fetch("https://rrikzb0kve.execute-api.us-east-1.amazonaws.com/Staging/tenantmanagement")
            .then(response => response.json()) // or response.text()
            .then(data => {

                //data[0]['status']='Pending'
                //this.state.users.filter(u => u.includes(this.state.filter))
                if (data)
                    setTenantCount(data);
                else
                    setTenantCount([]);
                setData(data.filter(opt => opt.status !== 'Active'))

                // var delhardcoded=[ {
                //     "infra": "Industry",
                //     "modeltype": "UW",
                //     "clientname": "exampleclient01",
                //     "lob": "WC",
                //     "primaryregion": "us-east-1",
                //     "secondaryregion": "us-west-2",
                //     "status": "Pending",
                //     "ipaddress": "24.239.131.31/32",
                //     "vpcsubnetrange": "10.0.20.0/22",
                //     "vpcsubnetone": "10.0.20.0/24",
                //     "vpcsubnettwo": "10.0.21.0/24",
                //     "vpcsubnetthree": "10.0.22.0/24",
                //     "projectname": "exampleClientWC",
                //     "clientspoc": "abc@client.com",
                //     "environment": "dev",
                //     "dashboardcount": 10,
                //     "useremail": "xyz@client.com",
                //     "cloudadmin": "pmazumder@deloitte.com, anandnaray@deloitte.com",
                //     "mlengineer": "gachandler@deloitte.com",
                //     "datascientist": "jaysarkar@deloitte.com",
                //     "tenantadmin": "hsrinivasan@deloitte.com",
                //     "requestsubmissiondate": "2023-06-19",
                //     "clientcreationtime": "2023-06-19 11:38:52.620000+00:00",
                //     "clientdeploymenttime": "2023-06-19 11:39:00+00:00",
                //     "deploymenttimeinmins": 0.0
                // }]
                // setData(delhardcoded)
                setAppData(data.filter(opt => opt.status === 'Active'))
                console.log(data)
                setAverage(data.filter(opt => opt.status !== 'Pending').map(num => parseInt(num.deploymenttimeinmins)).reduce((a, b) => a + b, 0) / data.length)
            })
            .catch(err => { console.log('error while fetching', err) });

    }, []);
    const tableColumns = [
        { title: "Tenant Name", field: "clientname" },
        //{ title: "Tenant Details", field: "tenantDetails" },
        {
            title: "Tenant Details",
            field: "tenantDetails",
            render: (rowData: string) => (
                <Link onClick={(e) => detailPage(e, rowData)} variant="body2" style={{ cursor: 'grab' }}>
                    Details
                </Link>
            ),
        },
        { title: "Model", field: "modeltype" },
        { title: "LOB", field: "lob" },
        {
            title: "Status", field: "status", value: 'Pending',
            cellStyle: (rowData: string) => {
                console.log(rowData)
                if (rowData === 'Active') {
                    return { backgroundColor: '#43B02A' };
                } else if (rowData === 'Inactive') {
                    return { backgroundColor: '#DA291C' };
                } else {
                    return { backgroundColor: '#ED8B00' };
                }
            }
            // cellStyle: {
            //             backgroundColor: ('status'==='Active') ? 'rgb(134, 188, 37)' : 'red',
            //              color: '#FFF',
            //              textAlign: 'center' as const,

            //          } 
        },
        // { title: "Okta User Group", field: "user" },

        { title: "Client Contact", field: "clientspoc" },

        { title: "Environment​", field: "environment" },

        { title: "Tenant Admin", field: "tenantadmin" },

        { title: "Onboard Date​", field: "requestsubmissiondate" },
        {
            title: "Tenant Login", field: "tenantLogin", render: () => (
                <div className="flex flex-row grow gap-x-4">
                    {/* <IconButton onClick={(e) => sendAlert(e)}>
                    <NotificationsActiveIcon 
                     />
                     </IconButton> */}
                    <Link href="https://okta.deloitteinnovation.us/" variant="body2">
                        AWS Login
                    </Link>
                </div>
            ),
        },
        // {
        //     title: "Onboard Delay (in days)​", field: "delay",
        //     cellStyle: (rowData: string) => {
        //         console.log(rowData)
        //         // if (rowData==='Active') {
        //         //   return { backgroundColor: 'rgb(134, 188, 37)' };
        //         // }else 
        //         if (rowData === '2 days') {
        //             return { backgroundColor: '#DA291C' };
        //         }
        //         // else{
        //         //     return { backgroundColor: 'rgb(225 200 25)' };
        //         // }
        //     }
        // },
        // {
        //     title: "Action​", field: "action", render: () => (
        //         <div className="flex flex-row grow gap-x-4">
        //             {/* <IconButton onClick={(e) => sendAlert(e)}>
        //             <NotificationsActiveIcon 
        //              />
        //              </IconButton> */}
        //             <CloseIcon />
        //         </div>
        //     ),
        // },

        // {
        //     title: "Data Scientist",
        //     field: "datascientist",
        //     render: () => (
        //         <input type="checkbox" />
        //     ),
        //     cellStyle: {
        //         //backgroundColor: '#039be5',
        //         color: '#FFF',
        //         textAlign: 'center' as const,

        //     },
        //     headerStyle: {
        //         alignItems: 'center' as const,
        //         //backgroundColor: '#039be5',
        //     }
        // },

    ];

    const detailPage = (e: any, rowdata: any) => {
        //alert(rowdata.tenantDetails)
        console.log(rowdata)
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "text/plain"
            },
            body: JSON.stringify({
                "ClientName": rowdata.clientname,
                "Region": rowdata.primaryregion
            })
        }
        var json = {};
        // fetch("https://hnji7ehubg.execute-api.us-east-1.amazonaws.com/Staging/tenant-details-extraction", options)
        //     .then(response => response.json()) // or response.text()
        //     .then(data => {
        //         setGlobalData((e: any) => {
        //             return {
        //                 ...e,
        //                 tenantDetail: data
        //             }
        //         })
        //         json = data;
        //         console.log(globalData)
        //     })
        //     .catch(err => { console.log('error while fetching', err) });

        getApiData(options, rowdata.clientname, rowdata.secondaryregion, rowdata.primaryregion,rowdata.status);
        console.log(json)

    }
    const getApiData = async (options, clientName, backupreg, prim,status) => {
        const response = await fetch("https://hnji7ehubg.execute-api.us-east-1.amazonaws.com/Staging/tenant-details-extraction", options)
            .then((response) => response.json());
        setGlobalData((e: any) => {
            return {
                ...e,
                tenantDetail: response,
                clientName: clientName,
                backupRegion: backupreg,
                primary: prim,
                status:status
            }
        })

        navigate('/tenantDetail')
    }
    const sendAlert = (event: any) => {
        alert('Notification Sent')
    }
    return (
        <div className="flex flex-col grow ">
            <div className="flex justify-between h-16 m-4">
                <div className="flex-shrink-0">
                    <img
                        className="image"
                        src={DeloitteLogo}
                        alt="Deloitte Logo"
                        style={{ height: "32px" }}
                    ></img>
                </div>
                <div className=" mx-4">
                    <h1 className="font-bold text-3xl">{'Tenant Overview Portal'}</h1>
                </div>
                <div className="self-start">
                    {/* <Link underline={"hover"} color="#4a8fdb" href="/">
                        {'<' + ' Home'}
                    </Link> */}
                </div>
            </div>
            <div className="self-start">
                <Link underline={"hover"} href="/" color="rgb(134 188 37)" >
                    {/* {'<' + ' Home'} */}
                    <HomeIcon sx={{ width: '2rem', height: '2rem', marginTop: "-3rem", marginLeft: '1rem', marginBottom: "0rem" }}></HomeIcon>
                </Link>
            </div>
            <div className="flex flex-row grow ">

                <div className="w-full  flex flex-row gap-x-4  p-4">

                    <div className="w-full  flex flex-col gap-y-4  p-4 border-e-white-950">

                        {/* <DarkCard className="flex flex-row gap-x-4 p-1">

                            {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                        {/* <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                                <div className="text-lg">Tenant Overview</div>
                            </div> */}
                        {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                        {/* </DarkCard> */}
                        <div className="flex flex-col gap-y-4  p-1  border-e-white-950" style={{ marginTop: '-2rem' }}>
                            <div className=" flex flex-row  p-4  border-e-white-950">
                                <Box sx={{ flexGrow: 0.2, width: 130 }}>
                                    <TextField id="outlined-basic" value='1' disabled label="Cluster Size" variant="outlined" />
                                </Box>
                                <Box sx={{ flexGrow: 0.2, width: 130, marginLeft: 4 }}>
                                    <TextField id="outlined-basic" disabled label="Total Tenants​" value={tenantCount.length ? tenantCount.length : '0'} variant="outlined" />

                                </Box>

                                <Box sx={{ flexGrow: 0.2, width: 130, marginLeft: 4 }}>
                                    <TextField id="outlined-basic" value={tenantCount.length ? tenantCount.length : '0'} disabled label="# of VPC" variant="outlined" />

                                </Box>
                                <Box sx={{ flexGrow: 0.2, width: 130, marginLeft: 4 }}>
                                    <TextField id="outlined-basic" value={avgDeployment ? Math.round(avgDeployment) + " mins" : '-'} disabled label="Avg Deployment Time" variant="outlined" />
                                </Box>
                            </div>
                        </div>
                        <div className="w-full flex flex-col gap-y-4  p-4 border-e-white-950">
                            {/* <DarkCard className="flex flex-row gap-x-4 p-1"> */}
                            {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                            {/* <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                                    <div className="text-lg">User Assignment</div>
                                </div> */}
                            {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                            {/* </DarkCard> */}


                            <div >
                                <Accordion defaultExpanded >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        alwaysOpen
                                        defaultExpanded
                                    >
                                        <Typography>Onboarding in progress</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <MaterialTable
                                            columns={tableColumns}
                                            title='Onboarding in progress'
                                            data={data}
                                            options={{
                                                search: false, showTitle: false,
                                                toolbar: false, paging: true,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    whiteSpace: "nowrap",
                                                    height: 50,
                                                    maxHeight: 50,
                                                    padding: 9.7,
                                                    textAlign: 'center',
                                                    margin: 5
                                                },
                                                rowStyle: {
                                                    textAlign: 'center',
                                                    alignItems: 'center'
                                                }
                                            }}
                                            localization={{
                                                pagination: {
                                                    labelDisplayedRows: '{from}-{to} of {count}'
                                                },
                                                toolbar: {
                                                    nRowsSelected: '{0} row(s) selected'
                                                },
                                                header: {
                                                    actions: 'Actions'
                                                },
                                                body: {
                                                    emptyDataSourceMessage: 'No records to display',
                                                    filterRow: {
                                                        filterTooltip: 'Filter'
                                                    }
                                                }
                                            }}
                                        />
                                        <Box>
                                            <div className=" flex flex-row  p-4  border-e-white-950">
                                                <RectangleIcon style={{ color: '#ED8B00' }}></RectangleIcon>
                                                <p style={{ color: '#ED8B00', marginLeft: '0.3rem' }}>Pending: Deployment In Progress</p>
                                                <RectangleIcon style={{ color: '#ED8B00', marginLeft: '5rem' }}></RectangleIcon>
                                                <p style={{ color: '#ED8B00', marginLeft: '0.3rem' }}>In Active: Staging Endpoint Created</p>
                                            </div>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        alwaysOpen
                                    >
                                        <Typography>Approved Tenants</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <MaterialTable style={{ overflowX: 'hidden' }}
                                            columns={tableColumns}
                                            title='Onboarding in progress'
                                            data={approvedData}
                                            options={{
                                                search: false, showTitle: false,
                                                toolbar: false, paging: true,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    whiteSpace: "nowrap",
                                                    height: 50,
                                                    maxHeight: 50,
                                                    padding: 9.7,
                                                    textAlign: 'center',
                                                    margin: 6
                                                },
                                                rowStyle: {
                                                    textAlign: 'center',
                                                    alignItems: 'center',
                                                    overflowX: 'hidden'
                                                }
                                            }}
                                            localization={{
                                                pagination: {
                                                    labelDisplayedRows: '{from}-{to} of {count}'
                                                },
                                                toolbar: {
                                                    nRowsSelected: '{0} row(s) selected'
                                                },
                                                header: {
                                                    actions: 'Actions'
                                                },
                                                body: {
                                                    emptyDataSourceMessage: 'No records to display',
                                                    filterRow: {
                                                        filterTooltip: 'Filter'
                                                    }
                                                }
                                            }}
                                        />
                                        <Box>
                                            <div className=" flex flex-row  p-4  border-e-white-950">
                                                <RectangleIcon style={{ color: '#43B02A' }}></RectangleIcon>
                                                <p style={{ color: '#43B02A', marginLeft: '0.3rem' }}>Active: Production Endpoint Created</p>
                                            </div>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            {/* <Fragment>
                                <MaterialTable
                                    columns={tableColumns}
                                    title='Onboarding in progress'
                                    data={data}
                                    options={{
                                        search: true, showTitle: true,
                                        toolbar: true, paging: false,
                                        actionsColumnIndex: -1
                                    }}/>
                            </Fragment>
                            <Fragment>
                                <MaterialTable
                                    columns={tableColumns}
                                    title='Approved Tenants'
                                    data={[]}
                                    options={{
                                        search: true, showTitle: true,
                                        toolbar: true, paging: false,
                                        actionsColumnIndex: -1
                                    }}/>
                            </Fragment> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TenantManagement;