import { ThemeProvider, Theme, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';





export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: "#181818",
      paper: "#181818"
    },
    primary: {
        main: "#86BC25"
    },
    secondary: {
      main: "#007CB0"
    }
  },
  typography: {
    fontFamily: 'sans-serif'
  },
});

function AppStyleWrapper({children}: {children?: React.ReactNode}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default AppStyleWrapper;