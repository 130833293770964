// @ts-nocheck
import React, { Fragment, useState } from "react";
import MaterialTable from "material-table";
import AppStyleWrapper from './AppStyleWrapper';
import DeloitteLogo from "./Deloitte_logo_ui_react.png"
import { useNavigate } from 'react-router-dom';
import Dropdown from "./form-inputs/Dropdown";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button } from "@mui/material";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import DialogActions from '@mui/material/DialogActions';
import exportFromJSON from 'export-from-json';
import { GlobalDataContext } from './context/GlobalDataContext'
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"

import { Autocomplete, Box, IconButton, SelectChangeEvent, Stack, Tab, Tabs, TextField, Table } from "@mui/material";


import Card, { CardProps } from "@mui/material/Card";
import { styled } from '@mui/material/styles';

const originalData = [
    {
        id: "client 1",
        name: "Jasnah",
        booleanValue: false
    },
    {
        id: "client 2",
        name: "Dal",
        booleanValue: true
    },
    {
        id: "client 3",
        name: "Kal",
        booleanValue: false
    }
];
const DarkCard = styled(Card)<CardProps>(({ theme }) => ({
    backgroundColor: '#333',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(248, 240, 227, 0.45)'
}));

function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: string) => {
        onClose(value);
    };

    return (
        <Dialog PaperProps={{
            style: {
                backgroundColor: 'white',
                boxShadow: 'none',
                color: 'black'
            },
        }} onClose={handleClose} open={open}>
            <DialogTitle>Client Onboarding details captured successfully</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface DropdownProps extends SelectProps<string> {
    title?: string,
    options: string[],
    size?: "small" | "medium"
    minWidth?: number,
    formControlStyles?: React.CSSProperties
}

type VarGraphOptions = {
    infra: string,
    modeltype: string,
    lob: string,
    primaryregion: string,
    secondaryregion: string,
    projectname: string,
    clientspoc: string,
    environment: string,
    dashboardcount: string,
    clientname: string,
    useremail: string,
    cloudadmin: string,
    mlengineer: string,
    datascientist: string,
    requestsubmissiondate: string
}

// type OptionsInput = {

//     setOptions: (newOptions: VarGraphOptions) => void,
//     selectedOptions: VarGraphOptions
// }

function NewUser() {
    const [globalData, setGlobalData] = React.useContext(GlobalDataContext) as any
    console.log(globalData)
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const originalData = [
        {
            user: "pmazumder@DELOITTE.com",
            name: "Prithvi Mazumder",
            cloudadmin: false,
            tenantAdmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "client 2",
            name: "Anand Naryanaswamy",
            cloudadmin: false,
            tenantAdmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "client 3",
            name: "Hariharan Srinivasan",
            cloudadmin: false,
            tenantAdmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "client 3",
            name: "Shandler Gavi",
            cloudadmin: false,
            tenantAdmin: false,
            mlengineer: false,
            datascientist: false
        },
        {
            user: "client 3",
            name: "Kumar Sarkar,Jay",
            cloudadmin: false,
            tenantAdmin: false,
            mlengineer: false,
            datascientist: false
        }
    ];

    const [data, setData] = useState(originalData);

    const tableColumns = [
        { title: "Users", field: "name" },

        {
            title: "Cloud Admin",
            field: "cloudadmin",
            render: () => (
                <input type="checkbox" onChange={(e) => changeAdmin(e.target.checked)} />
            ),
            cellStyle: {
                //backgroundColor: '#039be5',
                color: '#FFF',
                textAlign: 'center' as const,

            },
            // rowStyle: () => {
            //     return {
            //       fontFamily: "Mulish-Regular",
            //       backgroundColor: "#fff",
            //       marginLeft: '2rem'
            //     };
            //   },
            headerStyle: {
                alignItems: 'right' as const,
                //backgroundColor: '#039be5',
            }
        },
        {
            title: "Tenant Admin",
            field: "tenantAdmin",
            render: () => (
                <input type="checkbox" />
            ),
            cellStyle: {
                //backgroundColor: '#039be5',
                color: '#FFF',
                textAlign: 'center' as const,

            },
            headerStyle: {
                alignItems: 'center' as const,
                //backgroundColor: '#039be5',
            }
        },
        {
            title: "ML Engineer",
            field: "mlengineer",
            render: () => (
                <input type="checkbox" />
            ),
            cellStyle: {
                //backgroundColor: '#039be5',
                color: '#FFF',
                textAlign: 'center' as const,

            },
            headerStyle: {
                alignItems: 'center' as const,
                //backgroundColor: '#039be5',
            }
        },
        {
            title: "Data Scientist",
            field: "datascientist",
            render: () => (
                <input type="checkbox" />
            ),
            cellStyle: {
                //backgroundColor: '#039be5',
                color: '#FFF',
                textAlign: 'center' as const,

            },
            headerStyle: {
                alignItems: 'center' as const,
                //backgroundColor: '#039be5',
            }
        },

    ];
    const changeAdmin = (event: any) => {
        console.log(event)


    }


    interface PageStateInConflict {
        options: VarGraphOptions,
        proposedOptions: VarGraphOptions,
        modalOpen: true
    }

    type PageState = PageStateNoConflict | PageStateInConflict

    const handleClose = (value: string) => {
        setOpen(false);
    };
    const [pageState, setPageState] = React.useState<PageState>({
        modalOpen: false,
        options: {
            infra: 'Industry',
            modelType: 'UW',
            lob: 'WC',
            primaryregion: 'us-east-1',
            secondaryregion: 'us-east-2',
            ipAddress: '',
            vpcSubnetRange: '',
            vpcSubnetOne: '',
            vpcSubnetTwo: '',
            vpcSubnetThree: '',
            clientname: ''

        }
    })



    return (
        <>

            <div className="flex flex-row grow ">
                <div className="grow mt-6 mt-0">
                    {/* <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                        <div className="text-lg">User Management</div>
                    </div> */}
                    <DarkCard className="flex flex-row gap-x-4 p-1">

                        {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                        <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                            <div className="text-lg">Create New User</div>
                        </div>
                        {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                    </DarkCard>

                    <div className=" flex flex-col gap-y-5 p-1  border-e-white-950">
                    <Box sx={{ flexGrow: 0.2, width: 180, marginLeft: 4 }}>
                                        <Dropdown
                                            title="Select Cluster"
                                            label="Select Tenant"
                                            labelId={`select-Infrastructure-label`}
                                            value=''
                                            options={['new']}
                                        />

                                    </Box>
                                    <Box sx={{ flexGrow: 0.2, width: 180, marginLeft: 4 }}>
                                        <Dropdown
                                            title="Process"
                                            label="Infrastructure"
                                            labelId={`select-Infrastructure-label`}
                                            value=''
                                            options={['demo-client-tenant']}
                                        />

                                    </Box>
                        <div className=" flex flex-row p-4  border-e-white-950">
                            <Box sx={{ flexGrow: 0.2, width: 200 }}>
                                <TextField id="outlined-basic" label="Name" variant="outlined" />

                            </Box>
                            <Box sx={{ flexGrow: 0.2, width: 200 }}>
                                <TextField id="outlined-basic" label="Role" variant="outlined" />

                            </Box>
                            <Box sx={{ flexGrow: 0.2, width: 200 }}>
                                <TextField id="outlined-basic" label="Email" variant="outlined" />

                            </Box>
                            <Box sx={{ flexGrow: 0.2, width: 200 }}>
                                <TextField id="outlined-basic" label="Justification" variant="outlined" />

                            </Box>
                        </div>
                        <div className="self-center w-full flex justify-between h-8 m-4  mt-0">
                            <Box sx={{ flexGrow: 5 }}>
                                <Button variant="outlined" >
                                    Submit
                                </Button></Box>
                        </div></div>
                    <div className="w-full flex flex-col gap-y-4  p-4 border-e-white-950">
                        {/* <DarkCard className="flex flex-row gap-x-4 p-1"> */}
                        {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                        {/* <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                                    <div className="text-lg">User Assignment</div>
                                </div> */}
                        {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                        {/* </DarkCard> */}
                        <Fragment>
                            <MaterialTable
                                columns={tableColumns}
                                title='User Assignment'
                                data={data}
                                options={{
                                    search: true, showTitle: true,
                                    toolbar: true, paging: false,
                                    actionsColumnIndex: -1
                                }}
                            // editable={{
                            //     onRowUpdate: (newData, oldData) =>
                            //       new Promise((resolve, reject) => {
                            //         setTimeout(() => {
                            //           console.log("old data: ", oldData);
                            //           const dataUpdate = [...data];
                            //           const index = 1;
                            //           dataUpdate[index] = newData;
                            //           setData([...dataUpdate]);
                            //         }, 1000);
                            //       })
                            //   }}
                            />
                        </Fragment>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewUser;