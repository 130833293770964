import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SidebarData } from './SidebarData';

const container = document.getElementById('root');

const root = createRoot(container!)

const FullApp = () => (
  // <Router>
  //   <Routes>
  //     <Route path='/' >
  //       {SidebarData.map((item, index) => (
  //         <Route
  //           path={item.path}
  //           key={index}
  //           element={<App item={item} />}
  //         />
  //       ))}
  //     </Route>
  //   </Routes>
  // </Router>
  
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

root.render(<FullApp />)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
