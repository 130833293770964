// @ts-nocheck
import React, { Fragment, useState } from "react";
import MaterialTable from "material-table";



import AppStyleWrapper from './AppStyleWrapper';
import DeloitteLogo from "./Deloitte_logo_ui_react.png"
import Dropdown from "./form-inputs/Dropdown";
import { Button } from "@mui/material";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CloseIcon from '@mui/icons-material/Close';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import HomeIcon from '@mui/icons-material/Home';
import TabPanel from '@mui/lab/TabPanel';
import { GlobalDataContext } from './context/GlobalDataContext'
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"

import { Autocomplete, Box, IconButton, SelectChangeEvent, Stacks, TextField, Table, Link, FormLabel } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Card, { CardProps } from "@mui/material/Card";
import { styled } from '@mui/material/styles';

import { createTheme } from "@mui/material";
const theme = createTheme({
    palette: {
        action: {
            disabled: 'red',
        }
    },
});

const DarkCard = styled(Card)<CardProps>(({ theme }) => ({
    backgroundColor: '#333',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(248, 240, 227, 0.45)'
}));

interface DropdownProps extends SelectProps<string> {
    title?: string,
    options: string[],
    size?: "small" | "medium"
    minWidth?: number,
    formControlStyles?: React.CSSProperties
}

type VarGraphOptions = {
    infra: string,
    modelType: string,
    lob: string,
    primaryregion: string,
    ipAddress: string,
    secondaryregion: string,
    vpcSubnetRange: string,
    vpcSubnetOne: string,
    vpcSubnetTwo: string,
    vpcSubnetThree: string,
    clientname: string
}

type OptionsInput = {

    setOptions: (newOptions: VarGraphOptions) => void,
    selectedOptions: VarGraphOptions
}

function TenantDetail() {
    const [globalData, setGlobalData] = React.useContext(GlobalDataContext)
    const originalData = [
        { model: "Model Status", modelstatus: globalData['tenantDetail'] && globalData['tenantDetail']['modelDetails'] ? globalData['tenantDetail']['modelDetails']['ModelApprovalStatus'] : '' },
        { model: "Model Version", modelstatus: globalData['tenantDetail'] && globalData['tenantDetail']['modelDetails'] ? globalData['tenantDetail']['modelDetails']['ModelPackageVersion'] : '' },
        { model: "Model Updated On", modelstatus: globalData['tenantDetail'] && globalData['tenantDetail']['modelDetails'] ? globalData['tenantDetail']['modelDetails']['LastModifiedTime'] : '' },

    ];
    const originalDataUrl = [
        { dash: "Internal Dashboard", urldisplay: "https://main.d3vy68lb25cq0a.amplifyapp.com/slicereport" },
        { dash: "External Dashboard", urldisplay: "https://main.d3vy68lb25cq0a.amplifyapp.com/" },
        { dash: "Stage Endpoint", urldisplay: globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] && globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'] ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions'] : "" },
        { dash: "Production Endpoint", urldisplay: globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] && globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0] ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions'] : "" }
    ];
    const [data, setData] = useState(originalData);
    const [dataUrl, setDataUrl] = useState(originalDataUrl);
    const tableColumns = [
        { title: "", field: "model" },
        { title: "Status", field: "modelstatus" }

    ];
    const tableColumnsUrl = [
        { title: "", field: "dash" },
        {
            title: "URL", field: "urldisplay",
            render: (rowData: any) => (
                <a
                    href={rowData.urldisplay}
                    target="_blank"
                    style={{ textDecoration: 'revert', color: '#4a8fdb' }}
                >
                    {rowData.urldisplay}
                </a>
            )
        },

    ];
    const [value, setValue] = useState('Primary')

    const [backup, setBackup] = useState(false)
    const [regiondisplay, setRegiondisplay] = useState(globalData.primary)
    const onChange = (event) => {
        //alert(event.target.value)
        //return (event: SelectChangeEvent) => {
        //let temp = { ...selectedOptions } as VarGraphOptions
        //temp[field] = event.target.value
        setValue(event.target.value)
        if (event.target.value === 'Backup') {
            setBackup(true)
            setRegiondisplay(globalData.backupRegion)
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({
                    "ClientName": globalData.clientName,
                    "Region": globalData.backupRegion
                })
            }
            getApiData(options, globalData.clientName);
        } else {
            setBackup(false)
            setRegiondisplay(globalData.primary)
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({
                    "ClientName": globalData.clientName,
                    "Region": globalData.primary
                })
            }
            getApiData(options, globalData.clientName);
        }
        //}
    }

    const getApiData = async (options, clientName) => {
        const response = await fetch("https://hnji7ehubg.execute-api.us-east-1.amazonaws.com/Staging/tenant-details-extraction", options)
            .then((response) => response.json());
        setGlobalData((e: any) => {
            return {
                ...e,
                tenantDetail: response,
                clientName: clientName
            }
        })
    }

    const sendAlert = (event: any) => {
        alert('Send Notification')
    }
    React.useEffect(() => {
        console.log((globalData || {}))
        if (backup === true)
            setRegiondisplay(globalData.backupRegion)
        else
            setRegiondisplay(globalData.primary)
    }, [globalData])
    const [tabvalue, tabsetValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        tabsetValue(newValue);
    };

    const approveStage = (event) => {
        //alert(event.target.value)
        //return (event: SelectChangeEvent) => {
        //let temp = { ...selectedOptions } as VarGraphOptions
        //temp[field] = event.target.value
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "text/plain",
                "x-api-key": "vmfxFWbWob8skAlBWaC0i7OFnRxfcJNE2KGWbOy4"
            },
            body: JSON.stringify({
                "clientName": globalData.clientName,
                "tenantName": globalData.clientName,
                "region": globalData.tenantDetail.Region,
                "vpc-id": null,
                "status": "Approved"
            })
        }


        // const options = {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "text/plain",
        //         "x-api-key": "vmfxFWbWob8skAlBWaC0i7OFnRxfcJNE2KGWbOy4"
        //     },
        //     body: {
        //         "clientName": globalData.clientName,
        //         "tenantName": globalData.clientName,
        //         "region": globalData.tenantDetail.Region,
        //         "vpc-id": null,
        //         "status": "Approved"
        //     }
        // }
        console.log(options)
        fetch("https://6hzgvez4vl.execute-api.us-east-1.amazonaws.com/default/staging-to-prod", options)
            .then(response => response.json()) // or response.text()
            .then(data => {
                console.log(globalData)
            })
            .catch(err => { console.log('error while fetching', err) });

        // fetch("https://6hzgvez4vl.execute-api.us-east-1.amazonaws.com/default/staging-to-prod", {
        //     method: "POST",
        //     headers: {
        //         "API Key": "vmfxFWbWob8skAlBWaC0i7OFnRxfcJNE2KGWbOy4",
        //         "body":
        //     },
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data);
        //     })
        //     .catch((error) => console.log(error));
        //}
    }
    return (
        <div className="flex flex-col grow ">
            <div className="flex justify-between h-16 m-4">
                <div className="flex-shrink-0">
                    <img
                        className="image"
                        src={DeloitteLogo}
                        alt="Deloitte Logo"
                        style={{ height: "32px" }}
                    ></img>
                </div>
                <div className=" mx-4">
                    <h1 className="font-bold text-3xl">{'Tenant Details Portal'}</h1>
                </div>
                <div className="self-start">
                    {/* <Link underline={"hover"} href="/" color="#4a8fdb" > */}
                    {/* {'<' + ' Home'} */}
                    {/* <HomeIcon sx={{ width: '2rem',  height: '2rem', marginTop: "-3rem",marginLeft:'1rem',marginBottom:"0rem"}}></HomeIcon> */}
                    {/* </Link> */}
                </div>
            </div>

            <div className="flex flex-col">
                <div className="self-start">
                    <Link underline={"hover"} href="/" color="rgb(134 188 37)" >
                        {/* {'<' + ' Home'} */}
                        <HomeIcon sx={{ width: '2rem', height: '2rem', marginTop: "-3rem", marginLeft: '1rem', marginBottom: "0rem" }}></HomeIcon>
                    </Link>
                </div>
                <div className="flex flex-row flex-wrap gap-y-8 justify-center gap-x-8 mt-2 w-full">
                    <Box sx={{ width: 300, maxWidth: 300, height: 10, marginLeft: '-6rem' }}>
                        <TextField disabled
                            sx={{
                                width: '25rem'
                            }}
                            id="outlined-basic" label="Tenant Name​" value={globalData.clientName && globalData.clientName ? globalData.clientName : ''} variant="outlined" />

                    </Box>

                    {/* <Box sx={{ width: 50, marginLeft: 5 }}>
                        <TextField sx={{ width: '10rem' }} id="outlined-basic" value='Tenant-Admin' disabled label="Okta Group Name" variant="outlined" />

                    </Box> */}
                    <Box sx={{ flexGrow: 0.5, width: 5, maxWidth: 150, marginLeft: 15 }}>
                        <Dropdown
                            title="Region"
                            label="Region"
                            labelId={`select-Region-label`}
                            value={value}
                            onChange={(e) => onChange(e)}
                            options={['Primary', 'Backup']}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 0.5, width: 300, marginLeft: 5 }}>
                        <TextField sx={{
                            width: '10rem', color: 'white'
                        }} id="outlined-basic" value={globalData.status === 'In-Active' || globalData.status === 'Active' ? 'Success' : 'Pending'} disabled label="Endpoint Status"
                            variant="outlined" />
                    </Box>
                </div>
                {/* flex flex-row flex-wrap gap-y-8 justify-center */}
                {/* <div className="w-full flex flex-row gap-y-4  p-4 border-e-white-950"> */}
                {/* <div className="w-full flex flex-col gap-y-4  p-4 border-e-white-950"> */}

                {/* </div> */}
                {/* <DarkCard className="w-full flex flex-row gap-x-4 mt-3 p-1 ">

                    {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                {/* <div style={{ color: 'white' }} className="flex flex-row mt-3 ml-5">
                    <div className="text-lg">{value} Region -  us-west-2 </div>
                </div> */}
                {/* <div className="flex flex-row  items-center mr-32 "></div> */}
                {/* </DarkCard> */}
                {/* <div className="w-full  flex flex-col gap-y-4  p-2 border-2 border-e-white-950"> */}
                <div className="flex flex-col gap-y-4 mt-1 p-8 ">
                    <Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 1 }}>
                        <FormLabel
                            sx={{
                                marginLeft: "0.71em",
                                marginTop: "-0.71em",
                                paddingLeft: "0.44em",
                                paddingRight: '0.44em',
                                zIndex: 2,
                                backgroundColor: (theme) => theme.palette.background.default,
                                position: "absolute",
                                fontSize: "1.25em",
                                width: 'auto',
                            }}>{value} Region -  {regiondisplay} </FormLabel>
                        {!backup ? (
                            <div className="flex flex-row gap-y-8 ml-6 mt-2">
                                <div className="  flex flex-col gap-y-4  p-2 border-e-white-950">

                                    {/* <DarkCard className="flex flex-row gap-x-4 p-1">

                            {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                                    {/* <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                                <div className="text-lg">Primary Region</div>
                            </div> */}
                                    {/* <div className="flex flex-row  items-center mr-32 "></div> */}

                                    {/* <Dropdown
                                            title="Region"
                                            label="Region"
                                            labelId={`select-Region-label`}
                                            value={value}
                                            onChange={(e) => onChange(e)}
                                            options={['Primary','Backup']}
                                        />
                        </DarkCard> */}
                                    <div className="width-0.5 flex flex-row border-e-white-950">
                                        <Box sx={{ flexGrow: 0.5 }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: 'rgb(134 188 37 / 75%)',
                                                        WebkitTextFillColor: 'rgb(134 188 37 / 75%)',
                                                        color: "rgba(255, 255, 255, 0.5)",
                                                    }
                                                }, "& .MuiInputLabel-root.Mui-disabled": {
                                                    //"& > fieldset": {
                                                    borderColor: 'rgb(134 188 37 / 75%)',
                                                    // "#86BC25",
                                                    color: 'rgb(134 188 37 / 75%)',
                                                    WebkitTextFillColor: "rgba(255, 255, 255, 0.5)",
                                                    //}
                                                }, "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "rgba(255, 255, 255, 0.5)",
                                                },
                                                width: '15rem', maxWidth: "15rem", marginTop: '1rem'
                                            }} id="outlined-basic" disabled label="VPC - ID​"
                                                value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['VPC ID'] : ''} variant="outlined" />

                                        </Box>

                                        <Box sx={{ flexGrow: 0., ml: '5rem' }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: "#86BC25",
                                                        WebkitTextFillColor: "#86BC25",
                                                    }
                                                }, width: '10rem', maxWidth: "10rem", marginTop: '1rem'
                                            }} id="outlined-basic"
                                                value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['CidrBlock'] : ''} disabled label="VPC CIDR Range" variant="outlined" />

                                        </Box>
                                    </div>
                                    <Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 3, width: '30rem' }}>
                                        {/* <FormLabel
                                        sx={{
                                            marginLeft: "0.71em",
                                            marginTop: "-0.71em",
                                            paddingLeft: "0.44em",
                                            paddingRight: '0.44em',
                                            zIndex: 2,
                                            backgroundColor: (theme) => theme.palette.background.default,
                                            position: "absolute",
                                            fontSize: "0.75em",
                                            width: 'auto',
                                        }}>{value} Region -  us-east-1 </FormLabel> */}


                                        <TabContext value={tabvalue} >
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                    <Tab label="Private Subnet" value="1" />
                                                    <Tab label="Public Subnet" value="2" disabled />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="1"> <Box sx={{ flexGrow: 0.5, width: 100, marginLeft: 2 }}>
                                                {/* <p  sx={{ width: '12rem', marginLeft: 1 }} >10.0.12.0/24</p> */}
                                                <div className="flex flex-row  flex border-e-white-100 ">
                                                    <Box>
                                                        <TextField sx={{ width: '12rem', marginLeft: -3 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][0]['CidrBlock'] : '-'}
                                                            disabled label="Subnet - 1"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '12rem', marginLeft: -3, marginTop: 2 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][1]['CidrBlock'] : '-'} disabled label="Subnet - 2"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '12rem', marginLeft: -3, marginTop: 2 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][2]['CidrBlock'] : '-'} disabled label="Subnet - 3"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '12rem', marginLeft: -3, marginTop: 2 }} id="outlined-basic" value='-' disabled label="Subnet - 4"
                                                            variant="outlined" /></Box>
                                                    <Box>
                                                        <TextField sx={{ width: '14.5rem', marginLeft: 3 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][0]['SubnetId'] : '-'} disabled label="Subnet - 1 - ID"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '14.5rem', marginLeft: 3, marginTop: 2 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][1]['SubnetId'] : '-'} disabled label="Subnet - 2 - ID"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '14.5rem', marginLeft: 3, marginTop: 2 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][2]['SubnetId'] : '-'} disabled label="Subnet - 3 - ID"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '14.5rem', marginLeft: 3, marginTop: 2 }} id="outlined-basic" value='-' disabled label="Subnet - 4 - ID"
                                                            variant="outlined" /></Box>
                                                </div>
                                            </Box></TabPanel>
                                            <TabPanel value="2"> <Box sx={{ flexGrow: 0.5, width: 50, marginLeft: 2 }}>
                                                {/* <p  sx={{ width: '12rem', marginLeft: 1 }} >10.0.12.0/24</p> */}

                                                <TextField sx={{ width: '12rem', marginLeft: 1 }} id="outlined-basic" value='-' disabled label="Subnet - 1 IP Range"
                                                    variant="outlined" />
                                            </Box></TabPanel>
                                        </TabContext>


                                    </Box>


                                </div>
                                {/* <div className="flex flex-col  border-r-2 border-e-white-950 "></div> */}
                                <div className="flex flex-col gap-y-1 p-1 border-e-white-950">
                                    {/* <div className="w-full flex flex-col gap-y-1 p-1 border-e-white-950"> */}
                                    {/* <Box sx={{ flexGrow: 0.5, width: '80%', borderColor: '#333', borderWidth: '0.1rem', marginLeft: 10 }}>
                                    <Fragment>
                                        <MaterialTable
                                            columns={tableColumns}
                                            title='User Assignment'
                                            data={data}
                                            options={{
                                                search: false, showTitle: false,
                                                toolbar: false, paging: false,
                                                actionsColumnIndex: -1
                                            }}
                                        />
                                    </Fragment>
                                </Box> */}

                                    <div className=" flex flex-row gap-y-1 p-1 ml-36 border-e-white-950">
                                        <Box sx={{ flexGrow: 0.5 }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: "#86BC25",
                                                        WebkitTextFillColor: "#86BC25",
                                                    }
                                                }, width: '15rem', maxWidth: '15rem', marginTop: '1rem'
                                            }} id="outlined-basic"
                                                value={globalData['tenantDetail'] && globalData['tenantDetail']['modelDetails'] ? globalData['tenantDetail']['modelDetails']['ModelApprovalStatus'] : ''} disabled
                                                label="Model Status" variant="outlined" />

                                        </Box>
                                        <Box sx={{ flexGrow: 0.5, ml: 5 }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: "#86BC25",
                                                        WebkitTextFillColor: "#86BC25",
                                                    }
                                                }, width: '12rem', maxWidth: '12rem', marginTop: '1rem'
                                            }} id="outlined-basic"
                                                value={globalData['tenantDetail'] && globalData['tenantDetail']['modelDetails'] ? globalData['tenantDetail']['modelDetails']['ModelPackageVersion'] : ''} disabled
                                                label={"Model Version"} variant="outlined" />

                                        </Box>
                                        <Box sx={{ flexGrow: 0.5, ml: 5 }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: "#86BC25",
                                                        WebkitTextFillColor: "#86BC25",
                                                    }
                                                }, width: '15rem', maxWidth: '15srem', marginTop: '1rem'
                                            }} id="outlined-basic"
                                                value={globalData['tenantDetail'] && globalData['tenantDetail']['modelDetails'] ?
                                                    globalData['tenantDetail']['modelDetails']['LastModifiedTime'] : ''} disabled
                                                label="Model Updated Date" variant="outlined" />

                                        </Box>
                                    </div>
                                    {/* <div className="w-full flex flex-row space-y-2 border-e-white-950"></div> */}

                                    <div className=" flex flex-col gap-y-1 p-1 ml-36 mt-10 border-e-white-950">
                                        <Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 1, height: 55, borderRadius: 1 }}>
                                            <FormLabel
                                                sx={{
                                                    marginLeft: "0.71em",
                                                    marginTop: "-0.71em",
                                                    paddingLeft: "0.44em",
                                                    paddingRight: '0.44em',
                                                    zIndex: 2,
                                                    backgroundColor: (theme) => theme.palette.background.default,
                                                    position: "absolute",
                                                    fontSize: "0.8em",
                                                    width: 'auto',
                                                    color: 'grey',
                                                    height: '1rem'
                                                }}>Internal Dashboard</FormLabel>
                                            <div style={{ height: '3rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                                {/* <Link underline={"hover"} color="#4a8fdb" href='https://main.d3vy68lb25cq0a.amplifyapp.com/slicereport' >
                                                    https://main.d3vy68lb25cq0a.amplifyapp.com/slicereport
                                                </Link> */}
                                                <Link underline={"hover"} color="#4a8fdb" href='https://dev9897.d3l2yuubk51gsz.amplifyapp.com' >
                                                    https://dev9897.d3l2yuubk51gsz.amplifyapp.com
                                                </Link>
                                            </div>

                                        </Box>
                                        <Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 1, height: 55, borderRadius: 1, marginTop: 5 }}>
                                            <FormLabel
                                                sx={{
                                                    marginLeft: "0.71em",
                                                    marginTop: "-0.71em",
                                                    paddingLeft: "0.44em",
                                                    paddingRight: '0.44em',
                                                    zIndex: 2,
                                                    backgroundColor: (theme) => theme.palette.background.default,
                                                    position: "absolute",
                                                    fontSize: "0.8em",
                                                    width: 'auto',
                                                    color: 'grey',
                                                    height: '1rem'
                                                }}>External Dashboard</FormLabel>
                                            <div style={{ height: '3rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                                <Link underline={"hover"} color="#4a8fdb" href='https://main.d3vy68lb25cq0a.amplifyapp.com' >
                                                    https://main.d3vy68lb25cq0a.amplifyapp.com
                                                </Link></div>

                                        </Box>
                                        {/* <Box sx={{ flexGrow: 0.5 }}>
                                            <TextField  type={"url"} sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                value={"https://main.d3vy68lb25cq0a.amplifyapp.com/"} 
                                                label="External Dashboard" variant="outlined" />

                                        </Box> */}
                                        <div className=" flex flex-row border-e-white-950">
                                            {/* <Box sx={{ flexGrow: 0.5 }}>
                                                <TextField sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                    value={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                        globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                        && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                        ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions'] : "-"} disabled
                                                    label="Staging Endpoint" variant="outlined" />

                                            </Box> */}
                                            {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0] ? (<Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 1, height: 55, borderRadius: 1, marginTop: 5 }}>
                                                    <FormLabel
                                                        sx={{
                                                            marginLeft: "0.71em",
                                                            marginTop: "-0.71em",
                                                            paddingLeft: "0.44em",
                                                            paddingRight: '0.44em',
                                                            zIndex: 2,
                                                            backgroundColor: (theme) => theme.palette.background.default,
                                                            position: "absolute",
                                                            fontSize: "0.8em",
                                                            width: 'auto',
                                                            color: 'grey',
                                                            height: '1rem'
                                                        }}>Staging Endpoint</FormLabel>
                                                    <div style={{ height: '3rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                                        {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                            && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions'] ? (
                                                            <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions']
                                                                ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions'] : "-"} >

                                                                {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                    globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                    && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                    ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions'] : "-"}
                                                            </Link>

                                                        ) : (
                                                            globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][1]['predictions'] ? (
                                                                <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                    globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                    && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                    && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][1]['predictions']
                                                                    ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][1]['predictions'] : "-"} >

                                                                    {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                        globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                        && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                        ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][1]['predictions'] : "-"}
                                                                </Link>) : (
                                                                <Link underline={"hover"} color="#4a8fdb" href="-"> - </Link>
                                                            )

                                                        )
                                                        }


                                                    </div>

                                                </Box>) : (<Box sx={{ flexGrow: 0.5 }}>
                                                    <TextField sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                        value={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['StagingRemovalDate']
                                                            ? "Staging Endpint Deleted On " + globalData['tenantDetail']['EndpointDetails']['Prod'][0]['StagingRemovalDate'] : "-"} disabled
                                                        label="Staging Endpoint" variant="outlined" />

                                                </Box>)}

                                            {/* <CheckIcon sx={{ marginTop: 7 }}></CheckIcon>
                                            <CloseIcon sx={{ marginTop: 7, marginLeft: 5 }}></CloseIcon> */}
                                            <div className=" flex flex-col border-e-white-950">
                                                <CheckIcon style={{ backgroundColor: '#86BC25', color: 'white' }}
                                                    sx={{ width: '2rem', fontSize: '0.5rem', height: '2rem', marginTop: 6, borderRadius: 5, marginLeft: '1rem', cursor: 'grab' }} onClick={approveStage}></CheckIcon>
                                                <p style={{ fontSize: '0.7rem', marginLeft: 10 }}>Approve</p>
                                            </div>
                                            <div className=" flex flex-col border-e-white-950 ml-5">
                                                <CloseIcon style={{ backgroundColor: '#e01048', color: 'white', borderRadius: 5, cursor: 'grab' }} sx={{
                                                    width: '2rem', fontSize: '0.5rem', marginLeft: '5rem', borderRadius: 5, height: '2rem', marginTop: 6,
                                                    marginLeft: 1
                                                }}>
                                                </CloseIcon>
                                                <p style={{ fontSize: '0.7rem', marginLeft: 7 }}>Reject</p>
                                            </div>
                                        </div>

                                        {/* <Box sx={{ flexGrow: 0.5 }}>
                                            <TextField sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                value={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                    globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                    && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                    ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions'] : "-"} disabled
                                                label="Production Endpoint" variant="outlined" />

                                        </Box> */}


                                        {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0] ? (
                                            <Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 1, height: 55, borderRadius: 1, marginTop: 5 }}>
                                                <FormLabel
                                                    sx={{
                                                        marginLeft: "0.71em",
                                                        marginTop: "-0.71em",
                                                        paddingLeft: "0.44em",
                                                        paddingRight: '0.44em',
                                                        zIndex: 2,
                                                        backgroundColor: (theme) => theme.palette.background.default,
                                                        position: "absolute",
                                                        fontSize: "0.8em",
                                                        width: 'auto',
                                                        color: 'grey',
                                                        height: '1rem'
                                                    }}>Production Endpoint</FormLabel>
                                                <div style={{ height: '3.2rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                                    {/* <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                        globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                        && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                        ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] : "-"} >
                                                        {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                            ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] : "-"}
                                                    </Link> */}

                                                    {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                        globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                        && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                        && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions'] ? (
                                                        <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions']
                                                            ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions'] : "-"} >

                                                            {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                                && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                                ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions'] : "-"}
                                                        </Link>

                                                    ) : (
                                                        globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] ? (
                                                            <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                                && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                                && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions']
                                                                ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] : "-"} >

                                                                {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                    globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                                    && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                                    ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] : "-"}
                                                            </Link>) : (
                                                            <Link underline={"hover"} color="#4a8fdb" href={"-"} >

                                                                -
                                                            </Link>
                                                        )
                                                    )}

                                                </div>

                                            </Box>
                                        ) : (<Box sx={{ flexGrow: 0.5 }}>
                                            <TextField sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                value={"-"} disabled
                                                label="Production Endpoint" variant="outlined" />

                                        </Box>)}

                                    </div>
                                    {/* <Box sx={{ flexGrow: 0.5, width: '80%', borderColor: '#333', borderWidth: '0.1rem', marginLeft: 10 }}>
                                    <Fragment>
                                        <MaterialTable
                                            columns={tableColumnsUrl}
                                            title='User Assignment'
                                            data={dataUrl}
                                            options={{
                                                search: false, showTitle: false,
                                                toolbar: false, paging: false,
                                                actionsColumnIndex: -1
                                            }}
                                        />
                                    </Fragment>
                                </Box> */}
                                </div>
                            </div>) : (
                            <div className="flex flex-row gap-y-8 ml-6 mt-2">
                                <div className="  flex flex-col gap-y-4  p-2 border-e-white-950">

                                    {/* <DarkCard className="flex flex-row gap-x-4 p-1">

                            {/* <div className="flex flex-row  items-center ml-96 "></div> */}
                                    {/* <div style={{ color: 'white' }} className="flex flex-row  ml-5">
                                <div className="text-lg">Primary Region</div>
                            </div> */}
                                    {/* <div className="flex flex-row  items-center mr-32 "></div> */}

                                    {/* <Dropdown
                                            title="Region"
                                            label="Region"
                                            labelId={`select-Region-label`}
                                            value={value}
                                            onChange={(e) => onChange(e)}
                                            options={['Primary','Backup']}
                                        />
                        </DarkCard> */}
                                    <div className="width-0.5 flex flex-row border-e-white-950">
                                        <Box sx={{ flexGrow: 0.5 }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: 'rgb(134 188 37 / 75%)',
                                                        WebkitTextFillColor: 'rgb(134 188 37 / 75%)',
                                                        color: "rgba(255, 255, 255, 0.5)",
                                                    }
                                                }, "& .MuiInputLabel-root.Mui-disabled": {
                                                    //"& > fieldset": {
                                                    borderColor: 'rgb(134 188 37 / 75%)',
                                                    // "#86BC25",
                                                    color: 'rgb(134 188 37 / 75%)',
                                                    WebkitTextFillColor: "rgba(255, 255, 255, 0.5)",
                                                    //}
                                                }, "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "rgba(255, 255, 255, 0.5)",
                                                },
                                                width: '15rem', maxWidth: "15rem", marginTop: '1rem'
                                            }} id="outlined-basic" disabled label="VPC - ID​"
                                                value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['VPC ID'] : ''} variant="outlined" />

                                        </Box>

                                        <Box sx={{ flexGrow: 0., ml: '5rem' }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: "#86BC25",
                                                        WebkitTextFillColor: "#86BC25",
                                                    }
                                                }, width: '10rem', maxWidth: "10rem", marginTop: '1rem'
                                            }} id="outlined-basic"
                                                value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['CidrBlock'] : ''} disabled label="VPC CIDR Range" variant="outlined" />

                                        </Box>
                                    </div>
                                    <Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 3, width: '30rem' }}>
                                        {/* <FormLabel
                                        sx={{
                                            marginLeft: "0.71em",
                                            marginTop: "-0.71em",
                                            paddingLeft: "0.44em",
                                            paddingRight: '0.44em',
                                            zIndex: 2,
                                            backgroundColor: (theme) => theme.palette.background.default,
                                            position: "absolute",
                                            fontSize: "0.75em",
                                            width: 'auto',
                                        }}>{value} Region -  us-east-1 </FormLabel> */}


                                        <TabContext value={tabvalue} >
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                    <Tab label="Private Subnet" value="1" />
                                                    <Tab label="Public Subnet" value="2" disabled />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="1"> <Box sx={{ flexGrow: 0.5, width: 100, marginLeft: 2 }}>
                                                {/* <p  sx={{ width: '12rem', marginLeft: 1 }} >10.0.12.0/24</p> */}
                                                <div className="flex flex-row  flex border-e-white-100 ">
                                                    <Box>
                                                        <TextField sx={{ width: '12rem', marginLeft: -3 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][0]['CidrBlock'] : '-'}
                                                            disabled label="Subnet - 1"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '12rem', marginLeft: -3, marginTop: 2 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][1]['CidrBlock'] : '-'} disabled label="Subnet - 2"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '12rem', marginLeft: -3, marginTop: 2 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][2]['CidrBlock'] : '-'} disabled label="Subnet - 3"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '12rem', marginLeft: -3, marginTop: 2 }} id="outlined-basic" value='-' disabled label="Subnet - 4"
                                                            variant="outlined" /></Box>
                                                    <Box>
                                                        <TextField sx={{ width: '14.5rem', marginLeft: 3 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][0]['SubnetId'] : '-'} disabled label="Subnet - 1 - ID"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '14.5rem', marginLeft: 3, marginTop: 2 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][1]['SubnetId'] : '-'} disabled label="Subnet - 2 - ID"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '14.5rem', marginLeft: 3, marginTop: 2 }} id="outlined-basic" value={globalData.tenantDetail && globalData.tenantDetail.VPCs ? globalData.tenantDetail.VPCs['0']['Subnets'][2]['SubnetId'] : '-'} disabled label="Subnet - 3 - ID"
                                                            variant="outlined" />
                                                        <TextField sx={{ width: '14.5rem', marginLeft: 3, marginTop: 2 }} id="outlined-basic" value='-' disabled label="Subnet - 4 - ID"
                                                            variant="outlined" /></Box>
                                                </div>
                                            </Box></TabPanel>
                                            <TabPanel value="2"> <Box sx={{ flexGrow: 0.5, width: 50, marginLeft: 2 }}>
                                                {/* <p  sx={{ width: '12rem', marginLeft: 1 }} >10.0.12.0/24</p> */}

                                                <TextField sx={{ width: '12rem', marginLeft: 1 }} id="outlined-basic" value='-' disabled label="Subnet - 1 IP Range"
                                                    variant="outlined" />
                                            </Box></TabPanel>
                                        </TabContext>


                                    </Box>


                                </div>
                                {/* <div className="flex flex-col  border-r-2 border-e-white-950 "></div> */}
                                <div className="flex flex-col gap-y-1 p-1 border-e-white-950">
                                    {/* <div className="w-full flex flex-col gap-y-1 p-1 border-e-white-950"> */}
                                    {/* <Box sx={{ flexGrow: 0.5, width: '80%', borderColor: '#333', borderWidth: '0.1rem', marginLeft: 10 }}>
                                    <Fragment>
                                        <MaterialTable
                                            columns={tableColumns}
                                            title='User Assignment'
                                            data={data}
                                            options={{
                                                search: false, showTitle: false,
                                                toolbar: false, paging: false,
                                                actionsColumnIndex: -1
                                            }}
                                        />
                                    </Fragment>
                                </Box> */}

                                    <div className=" flex flex-row gap-y-1 p-1 ml-36 border-e-white-950">
                                        <Box sx={{ flexGrow: 0.5 }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: "#86BC25",
                                                        WebkitTextFillColor: "#86BC25",
                                                    }
                                                },
                                                width: '15rem', maxWidth: '15rem', marginTop: '1rem'
                                            }} id="outlined-basic"
                                                // value={globalData['tenantDetail'] && globalData['tenantDetail']['modelDetails'] ? globalData['tenantDetail']['modelDetails']['ModelApprovalStatus'] : ''} 
                                                value={'Refer Primary Region'}
                                                disabled
                                                label="Model Status" variant="outlined" />

                                        </Box>
                                        <Box sx={{ flexGrow: 0.5, ml: 5 }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: "#86BC25",
                                                        WebkitTextFillColor: "#86BC25",
                                                    }
                                                },
                                                width: '12rem', maxWidth: '12rem', marginTop: '1rem'
                                            }} id="outlined-basic"
                                                // value={globalData['tenantDetail'] && globalData['tenantDetail']['modelDetails'] ? globalData['tenantDetail']['modelDetails']['ModelPackageVersion'] : ''} 
                                                value={'Refer Primary Region'}
                                                disabled
                                                label={"Model Version"} variant="outlined" />

                                        </Box>
                                        <Box sx={{ flexGrow: 0.5, ml: 5 }}>
                                            <TextField sx={{
                                                "& .MuiInputBase-root.Mui-disabled": {
                                                    "& > fieldset": {
                                                        borderColor: "#86BC25",
                                                        WebkitTextFillColor: "#86BC25",
                                                    }
                                                },
                                                width: '15rem', maxWidth: '15srem', marginTop: '1rem'
                                            }} id="outlined-basic"
                                                // value={globalData['tenantDetail'] && globalData['tenantDetail']['modelDetails'] ?
                                                //     globalData['tenantDetail']['modelDetails']['LastModifiedTime'] : ''}
                                                value={'Refer Primary Region'}
                                                disabled
                                                label="Model Updated Date" variant="outlined" />

                                        </Box>
                                    </div>
                                    {/* <div className="w-full flex flex-row space-y-2 border-e-white-950"></div> */}

                                    <div className=" flex flex-col gap-y-1 p-1 ml-36 mt-10 border-e-white-950">
                                        <Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 1, height: 55, borderRadius: 1 }}>
                                            <FormLabel
                                                sx={{
                                                    marginLeft: "0.71em",
                                                    marginTop: "-0.71em",
                                                    paddingLeft: "0.44em",
                                                    paddingRight: '0.44em',
                                                    zIndex: 2,
                                                    backgroundColor: (theme) => theme.palette.background.default,
                                                    position: "absolute",
                                                    fontSize: "0.8em",
                                                    width: 'auto',
                                                    color: 'grey',
                                                    height: '1rem'
                                                }}>Internal Dashboard</FormLabel>
                                            <div style={{ height: '3rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                                <Link underline={"hover"} color="#4a8fdb" href='https://dev9897.d3l2yuubk51gsz.amplifyapp.com' >
                                                    https://dev9897.d3l2yuubk51gsz.amplifyapp.com
                                                </Link></div>

                                        </Box>
                                        <Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 1, height: 55, borderRadius: 1, marginTop: 5 }}>
                                            <FormLabel
                                                sx={{
                                                    marginLeft: "0.71em",
                                                    marginTop: "-0.71em",
                                                    paddingLeft: "0.44em",
                                                    paddingRight: '0.44em',
                                                    zIndex: 2,
                                                    backgroundColor: (theme) => theme.palette.background.default,
                                                    position: "absolute",
                                                    fontSize: "0.8em",
                                                    width: 'auto',
                                                    color: 'grey',
                                                    height: '1rem'
                                                }}>External Dashboard</FormLabel>
                                            <div style={{ height: '3rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                                <Link underline={"hover"} color="#4a8fdb" href='https://main.d3vy68lb25cq0a.amplifyapp.com' >
                                                    https://main.d3vy68lb25cq0a.amplifyapp.com
                                                </Link></div>

                                        </Box>
                                        {/* <Box sx={{ flexGrow: 0.5 }}>
                                            <TextField  type={"url"} sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                value={"https://main.d3vy68lb25cq0a.amplifyapp.com/"} 
                                                label="External Dashboard" variant="outlined" />

                                        </Box> */}
                                        <div className=" flex flex-row border-e-white-950">
                                            {/* <Box sx={{ flexGrow: 0.5 }}>
                                                <TextField sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                    value={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                        globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                        && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                        ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions'] : "-"} disabled
                                                    label="Staging Endpoint" variant="outlined" />

                                            </Box> */}
                                            {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0] ? (<Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 1, height: 55, borderRadius: 1, marginTop: 5 }}>
                                                    <FormLabel
                                                        sx={{
                                                            marginLeft: "0.71em",
                                                            marginTop: "-0.71em",
                                                            paddingLeft: "0.44em",
                                                            paddingRight: '0.44em',
                                                            zIndex: 2,
                                                            backgroundColor: (theme) => theme.palette.background.default,
                                                            position: "absolute",
                                                            fontSize: "0.8em",
                                                            width: 'auto',
                                                            color: 'grey',
                                                            height: '1rem'
                                                        }}>Staging Endpoint</FormLabel>
                                                    <div style={{ height: '3rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                                        {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                            && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions'] ? (
                                                            <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions']
                                                                ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions'] : "Not Applicable"} >

                                                                {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                    globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                    && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                    ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][0]['predictions'] : "Not Applicable"}
                                                            </Link>

                                                        ) : (
                                                            globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][1]['predictions'] ? (
                                                                <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                    globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                    && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                    && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][1]['predictions']
                                                                    ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][1]['predictions'] : "Not Applicable"} >

                                                                    {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                        globalData['tenantDetail']['EndpointDetails']['Staging'] && globalData['tenantDetail']['EndpointDetails']['Staging'][0]
                                                                        && globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis']
                                                                        ? globalData['tenantDetail']['EndpointDetails']['Staging'][0]['apis'][1]['predictions'] : "Not Applicable"}
                                                                </Link>) : (
                                                                <Link underline={"hover"} color="#4a8fdb" href="-"> Not Applicable </Link>
                                                            )

                                                        )
                                                        }


                                                    </div>

                                                </Box>) : (<Box sx={{ flexGrow: 0.5 }}>
                                                    <TextField sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                        value={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['StagingRemovalDate']
                                                            ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['StagingRemovalDate'] : "Not Applicable"} disabled
                                                        label="Staging Endpoint" variant="outlined" />

                                                </Box>)}

                                            {/* <CheckIcon sx={{ marginTop: 7 }}></CheckIcon>
                                            <CloseIcon sx={{ marginTop: 7, marginLeft: 5 }}></CloseIcon> */}
                                            {/* <div className=" flex flex-col border-e-white-950">
                                                <CheckIcon style={{ backgroundColor: '#86BC25', color: 'white' }}
                                                    sx={{ width: '2rem', fontSize: '0.5rem', height: '2rem', marginTop: 6, borderRadius: 5, marginLeft: '1rem', cursor: 'grab' }} onClick={approveStage}></CheckIcon>
                                                <p style={{ fontSize: '0.7rem', marginLeft: 10 }}>Approve</p>
                                            </div>
                                            <div className=" flex flex-col border-e-white-950 ml-5">
                                                <CloseIcon style={{ backgroundColor: '#e01048', color: 'white', borderRadius: 5, cursor: 'grab' }} sx={{
                                                    width: '2rem', fontSize: '0.5rem', marginLeft: '5rem', borderRadius: 5, height: '2rem', marginTop: 6,
                                                    marginLeft: 1
                                                }}>
                                                </CloseIcon>
                                                <p style={{ fontSize: '0.7rem', marginLeft: 7 }}>Reject</p>
                                            </div> */}
                                        </div>

                                        {/* <Box sx={{ flexGrow: 0.5 }}>
                                            <TextField sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                value={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                    globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                    && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                    ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions'] : "-"} disabled
                                                label="Production Endpoint" variant="outlined" />

                                        </Box> */}


                                        {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0] ? (
                                            <Box sx={{ flexGrow: 1, border: '1px solid grey', mt: 1, height: 55, borderRadius: 1, marginTop: 5 }}>
                                                <FormLabel
                                                    sx={{
                                                        marginLeft: "0.71em",
                                                        marginTop: "-0.71em",
                                                        paddingLeft: "0.44em",
                                                        paddingRight: '0.44em',
                                                        zIndex: 2,
                                                        backgroundColor: (theme) => theme.palette.background.default,
                                                        position: "absolute",
                                                        fontSize: "0.8em",
                                                        width: 'auto',
                                                        color: 'grey',
                                                        height: '1rem'
                                                    }}>Backup Endpoint</FormLabel>
                                                <div style={{ height: '3.2rem', marginTop: '1rem', marginLeft: '1rem' }}>
                                                    {/* <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                        globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                        && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                        ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] : "-"} >
                                                        {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                            ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] : "-"}
                                                    </Link> */}

                                                    {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                        globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                        && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                        && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions'] ? (
                                                        <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions']
                                                            ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions'] : "-"} >

                                                            {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                                && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                                ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][0]['predictions'] : "-"}
                                                        </Link>

                                                    ) : (
                                                        globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                            globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                            && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] ? (
                                                            <Link underline={"hover"} color="#4a8fdb" href={globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                                && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                                && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions']
                                                                ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] : "-"} >

                                                                {globalData['tenantDetail'] && globalData['tenantDetail']['EndpointDetails'] &&
                                                                    globalData['tenantDetail']['EndpointDetails']['Prod'] && globalData['tenantDetail']['EndpointDetails']['Prod'][0]
                                                                    && globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis']
                                                                    ? globalData['tenantDetail']['EndpointDetails']['Prod'][0]['apis'][1]['predictions'] : "-"}
                                                            </Link>) : (
                                                            <Link underline={"hover"} color="#4a8fdb" href={"-"} >

                                                                -
                                                            </Link>
                                                        )
                                                    )}

                                                </div>

                                            </Box>
                                        ) : (<Box sx={{ flexGrow: 0.5 }}>
                                            <TextField sx={{ width: '40rem', maxWidth: '40rem', marginTop: 5 }} id="outlined-basic"
                                                value={"-"} disabled
                                                label="Backup Endpoint" variant="outlined" />

                                        </Box>)}

                                    </div>
                                    {/* <Box sx={{ flexGrow: 0.5, width: '80%', borderColor: '#333', borderWidth: '0.1rem', marginLeft: 10 }}>
                                    <Fragment>
                                        <MaterialTable
                                            columns={tableColumnsUrl}
                                            title='User Assignment'
                                            data={dataUrl}
                                            options={{
                                                search: false, showTitle: false,
                                                toolbar: false, paging: false,
                                                actionsColumnIndex: -1
                                            }}
                                        />
                                    </Fragment>
                                </Box> */}
                                </div>
                            </div>)}

                    </Box>
                </div>

                {/* <div className="flex flex-col  border-r-2 border-e-white-950 "></div> */}


            </div >
        </div>
    );
}

export default TenantDetail;