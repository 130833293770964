import React, { useState } from 'react';
import AppStyleWrapper from './AppStyleWrapper';
import OptionsSelector from './OptionsSelector';

import NavBar from './NavBar';
import DeloitteLogo from "./Deloitte_logo_ui_react.png"
import Dropdown from "./form-inputs/Dropdown";
import { Button } from "@mui/material";
import { PageDefinition } from './SidebarData';

import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"

import { Autocomplete, Box, IconButton, SelectChangeEvent, Stack, Tab, Tabs, TextField } from "@mui/material";


import Card, { CardProps } from "@mui/material/Card";
import { styled } from '@mui/material/styles';
type LayoutProps = {
    page: import('./SidebarData').PageDefinition
}
const DarkCard = styled(Card)<CardProps>(({ theme }) => ({
    backgroundColor: '#333',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(248, 240, 227, 0.45)'
}));

interface DropdownProps extends SelectProps<string> {
    title?: string,
    options: string[],
    size?: "small" | "medium"
    minWidth?: number,
    formControlStyles?: React.CSSProperties
}


type VarGraphOptions = {
    infra: string,
    modeltype: string,
    lob: string,
    primaryregion: string,
    secondaryregion: string,
    projectname: string,
    clientspoc: string,
    environment: string,
    dashboardcount: string,
    clientname: string,
    useremail: string,
    cloudadmin: string,
    mlengineer: string,
    datascientist: string,
    requestsubmissiondate: string,
    status:string
}
type OptionsInput = {
    setOptions: (newOptions: VarGraphOptions) => void,
    selectedOptions: VarGraphOptions
}

type VarOptionsConflictResolution = {
    options: VarGraphOptions,
    proposedOptions: VarGraphOptions
}

const getConflicts = (options: VarGraphOptions, proposedOptions: VarGraphOptions) => {
    return {
        options,
        proposedOptions: {
            ...proposedOptions
        }
    } as VarOptionsConflictResolution
}


function ClientPage() {

    interface PageStateNoConflict {
        options: VarGraphOptions
    }

    interface PageStateInConflict {
        options: VarGraphOptions
    }

    type PageState = PageStateNoConflict | PageStateInConflict


    const setOptions = (proposedOptions: VarGraphOptions) => {
        const conflictResult = getConflicts(pageState.options, proposedOptions)
        console.log(conflictResult)
        setPageState({
            options: conflictResult.proposedOptions
        })
    }

    const [pageState, setPageState] = React.useState<PageState>({
        options: {
            infra: 'Industry',
            modeltype: 'UW',
            lob: 'WC',
            primaryregion: 'us-east-1',
            secondaryregion: 'us-east-2',
            projectname: '',
            clientspoc: '',
            environment: '',
            dashboardcount: '',
            clientname: '',
            useremail: '',
            cloudadmin:'',
            mlengineer:'',
            datascientist:'',
            requestsubmissiondate:''  ,
            status:''  
        }
    })
    return (
        <div>
            <OptionsSelector selectedOptions={pageState.options} setOptions={setOptions} />
        </div>
    );
}

export default ClientPage;