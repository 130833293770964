import React, { useState } from 'react';
import AppStyleWrapper from './AppStyleWrapper';
import OptionsSelector from './OptionsSelector';
import Layout from './Layout';
import { GlobalDataContext } from './context/GlobalDataContext'
import TenantManagement from './TenantManagement';
import NewUser from './NewUser';
import TenantDetail from './TenantDetail';
import MainPage from './MainPage';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import NavBar from './NavBar';
import DeloitteLogo from "./Deloitte_logo_ui_react.png"
import Dropdown from "./form-inputs/Dropdown";
import { Button } from "@mui/material";
import { PageDefinition } from './SidebarData';

import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"

import { Autocomplete, Box, IconButton, SelectChangeEvent, Stack, Tab, Tabs, TextField } from "@mui/material";


import Card, { CardProps } from "@mui/material/Card";
import { styled } from '@mui/material/styles';
import ClusterCreation from './ClusterCreation';
import ClientPage from './ClientPage';
type LayoutProps = {
  page: import('./SidebarData').PageDefinition
}
const DarkCard = styled(Card)<CardProps>(({ theme }) => ({
  backgroundColor: '#333',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderStyle: 'solid',
  borderRadius: '10px',
  boxShadow: '0 2px 8px rgba(248, 240, 227, 0.45)'
}));

interface DropdownProps extends SelectProps<string> {
  title?: string,
  options: string[],
  size?: "small" | "medium"
  minWidth?: number,
  formControlStyles?: React.CSSProperties
}


type VarGraphOptions = {
  infra: string,
  modeltype: string,
  lob: string,
  primaryregion: string,
  secondaryregion: string,
  ipAddress: string,
  clientname: string,
  vpcSubnetRange: string,
  vpcSubnetOne: string,
  vpcSubnetTwo: string,
  vpcSubnetThree: string,
}
type OptionsInput = {
  setOptions: (newOptions: VarGraphOptions) => void,
  selectedOptions: VarGraphOptions
}

type VarOptionsConflictResolution = {
  options: VarGraphOptions,
  proposedOptions: VarGraphOptions
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    // pageName:"Client Onboarding Portal"

  },
  {
    path: "/cluster",
    element: <ClusterCreation />,
    // pageName:"Cluster Onboarding Portal"
  },
  {
    path: '/tenant',
    element: <ClientPage />,
    // pageName:"Client Onboarding Portal"
  },
  {
    path: '/tenantoverview',
    element: <TenantManagement />,
    // pageName:"Tenant Detailed Overview"
  },
  {
    path: '/user',
    element: <NewUser />,
    // pageName:"User Onboarding Portal"
  },
  {
    path: '/tenantDetail',
    element: <TenantDetail />,
    // pageName:"Tenant Management Portal"
  }
]);

const getConflicts = (options: VarGraphOptions, proposedOptions: VarGraphOptions) => {
  return {
    options,
    proposedOptions: {
      ...proposedOptions
    }
  } as VarOptionsConflictResolution
}


function App() {

  interface PageStateNoConflict {
    options: VarGraphOptions
  }

  interface PageStateInConflict {
    options: VarGraphOptions
  }

  type PageState = PageStateNoConflict | PageStateInConflict


  const setOptions = (proposedOptions: VarGraphOptions) => {
    const conflictResult = getConflicts(pageState.options, proposedOptions)
    console.log(conflictResult)
    setPageState({
      options: conflictResult.proposedOptions
    })
  }

  const [pageState, setPageState] = React.useState<PageState>({
    options: {
      infra: 'Industry',
      modeltype: 'UW',
      lob: 'WC',
      primaryregion: 'us-east-1',
      secondaryregion: 'us-east-2',
      ipAddress: '',
      vpcSubnetRange: '',
      vpcSubnetOne: '',
      vpcSubnetTwo: '',
      vpcSubnetThree: '',
      clientname: ''
    }
  })

  const globalData = React.useState({});
  return (

    <React.StrictMode>
      <AppStyleWrapper>
        <GlobalDataContext.Provider value={globalData}>
          <RouterProvider router={router} />
        </GlobalDataContext.Provider>
        {/* <Layout page={item}>
        {item.component ? <item.component /> : <div>This page is still under construction.</div>}
      </Layout> */}
      </AppStyleWrapper >

    </React.StrictMode>
  );
}

export default App;